import { useEffect } from 'react';

const useLockBodyScroll = (shouldLock: boolean): void => {
  useEffect(() => {
    // Check if the browser supports the 'body' property
    const body = document.body;
    if (!body) return;

    // Save the current scroll position
    const scrollY = window.scrollY;
    const bodyStyleOverflow = body.style.overflow;

    if (shouldLock) {
      // Lock the body scroll
      body.style.overflow = 'hidden';
      // Adjust the scroll position to prevent the page from jumping
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}px`;
    } else {
      // Unlock the body scroll
      body.style.overflow = bodyStyleOverflow;
      // Reset the scroll position
      const scrollPosition = parseInt(body.style.top || '0', 10);
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, Math.abs(scrollPosition));
    }

    // Cleanup function
    return () => {
      // Unlock the body scroll and reset styles when component unmounts
      body.style.overflow = bodyStyleOverflow;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, Math.abs(scrollY));
    };
  }, [shouldLock]);
};

export { useLockBodyScroll };
