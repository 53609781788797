import { SVGComponentProps, Star01 } from '@v2/ui';
import { cn } from '@v2/utils';

type Props = {
  count?: number;
  active: number;
  className?: string;
} & SVGComponentProps;

export const StarRatings = ({
  count = 5,
  active,
  className,
  ...svgProps
}: Props) => {
  const arr = Array.from(Array(count), (x, i) => i + 1);
  const size = svgProps.size || '20';
  return (
    <div className={cn('flex flex-row gap-y-1', className)}>
      {arr.map((item) => (
        <Star01
          // className={clsx(classes.star, active >= item && classes.active)}
          key={item}
          pathProps={{
            fill: active >= item ? '#667085' : 'transparent',
            stroke: active >= item ? 'none' : '#667085',
          }}
          size={size}
          {...svgProps}
        />
      ))}
    </div>
  );
};
