import { DIRTY_DATA_KEYS } from '@config/storageKeys';
import useStorage from '@hooks/useStorage';
import devConsole from '@utils/developer-console';

export default function invalidatePackageData() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const storage = useStorage();
  try {
    DIRTY_DATA_KEYS.forEach((item) => storage.removeItem(item));
  } catch (e) {
    devConsole(e);
  }
}
