import React, { ReactNode, forwardRef } from 'react';
import cn from 'classnames';
import { Typography } from '@v2/ui';

type InputWithLabelProps = {
  icon: React.ReactNode;
  label: string;
  value: string | ReactNode | undefined;
};

export const InputWithLabel = forwardRef<
  HTMLButtonElement,
  InputWithLabelProps
>((props, ref) => {
  const { icon, label, value, ...rest } = props;

  return (
    <button
      {...rest}
      ref={ref}
      type="button"
      className={cn(
        'group relative inline-flex items-center justify-start gap-x-3.5 rounded-xl border border-gray-300 bg-white text-gray-800',
        'h-[72px] w-full px-4 focus-visible:border-gray-800 focus-visible:outline-none lg:flex-1'
      )}
    >
      {icon}
      <div className="flex flex-col items-start justify-start gap-y-1">
        <Typography
          size="tsm"
          variant="span"
          className="font-medium text-gray-500"
        >
          {label}
        </Typography>
        <Typography
          size="tmd"
          variant="h3"
          className="max-w-[25ch] overflow-x-auto truncate font-medium"
          suppressHydrationWarning
        >
          {value || ''}
        </Typography>
      </div>
    </button>
  );
});
