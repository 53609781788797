'use client';
import { MAP_SECTION_COLOR, SEAT_MAP_ID } from '@config/index';
import useTevomaps, { PublicApi } from '@hooks/use-tevomaps';
import useMinimumPrice from '@hooks/useMinimumPrice';
import useMutationObserver from '@hooks/useMutationObserver';
import { LeagueEvent } from '@interfaces/APITravel';
import { TicketGroup } from '@interfaces/ticketevolution/tikets';
import { useEffect, useRef, useState } from 'react';
import { useTicketsStore } from 'src/stores/tickets-store';

type Props = {
  event: LeagueEvent;
  ticketGroups: TicketGroup[];
};

import { memo } from 'react';

export const ArenaView = memo(function ArenaView({
  event,
  ticketGroups,
}: Props) {
  const { venue, configuration } = event;
  const { id: venueId } = venue ?? {};
  const { id: configurationId } = configuration ?? {};
  const { Tevomaps, isLoaded } = useTevomaps();
  const SeatMapRef = useRef<PublicApi | null>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const [isMapAvailable, setIsMapAvailable] = useState(true);

  const ticketsQuantity = useTicketsStore((state) => state.ticketsQuantity);
  const ticketSelected = useTicketsStore(
    (state) => state.ticketSelected?.section
  );
  const isOnlyElectronic = useTicketsStore((state) => state.isOnlyElectronic);

  const selectedGroups = useTicketsStore((state) => state.selectedGroups);
  const setSelectedGroups = useTicketsStore((state) => state.setSelectedGroups);
  const [min, max] = useTicketsStore((state) => state.priceRange);

  const { ticketPrice } = useMinimumPrice([], ticketGroups, ticketsQuantity);

  useEffect(() => {
    if (!isLoaded) return;
    if (Tevomaps && ticketGroups) {
      if (configurationId && mapRef.current !== null) {
        const ticketGroupsDiff = ticketGroups.filter((item) => {
          const {
            retail_price,
            quantity,
            eticket: Eticket,
            splits,
            section,
          } = item;
          return (
            retail_price >= min &&
            retail_price * ticketsQuantity <= max &&
            quantity >= ticketsQuantity &&
            ((isOnlyElectronic && Eticket) || !isOnlyElectronic) &&
            splits.includes(ticketsQuantity)
          );
        });

        const seatmap = new Tevomaps({
          venueId,
          configurationId,
          ticketGroups: ticketGroupsDiff,
          sectionPercentiles: {
            0.2: MAP_SECTION_COLOR,
            0.4: MAP_SECTION_COLOR,
            0.6: MAP_SECTION_COLOR,
            0.8: MAP_SECTION_COLOR,
            1: MAP_SECTION_COLOR,
          },
          showLegend: false,
          // highlightSection: selectedGroups,
          selectedSections: selectedGroups,

          onSelection: (ids) => {
            setSelectedGroups(ids);
          },
          showControls: true,
        });

        if (SeatMapRef.current) {
          SeatMapRef.current?.updateTicketGroups(ticketGroupsDiff);
        } else {
          SeatMapRef.current = seatmap.build(mapRef.current?.id ?? SEAT_MAP_ID);
        }
      }
    }

    () => (SeatMapRef.current = null);
  }, [
    isLoaded,
    Tevomaps,
    configurationId,
    mapRef,
    venueId,
    max,
    min,
    isOnlyElectronic,
    ticketsQuantity,
    ticketPrice,
    ticketGroups,
    ticketSelected,
  ]);
  const checkMapImgSrc = (changes) => {
    changes?.forEach((change) => {
      if (change.type === 'childList' && change.addedNodes.length) {
        change.addedNodes.forEach((addedNode) => {
          if (addedNode.src && addedNode.src.includes('not_available.jpg')) {
            setIsMapAvailable(true);
          }
        });
      }
    });
  };

  useMutationObserver(mapRef, checkMapImgSrc);

  if (!ticketGroups?.length) return null;

  return (
    <div className="h-[220px] md:h-[720px]">
      {!isMapAvailable && (
        <div style={{ margin: 'auto' }}>
          <small>Seating chart not available.</small>
        </div>
      )}
      {isMapAvailable && (
        <div
          ref={mapRef}
          id={SEAT_MAP_ID}
          className="h-[220px] w-full md:h-[720px]"
        />
      )}
    </div>
  );
});
