import type { PropertyDetails } from '@interfaces/expedia/property';
import {
  AlertTriangle,
  Button,
  Check,
  Close,
  CurrencyDollar,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DogBowl,
  InfoCircle,
  List,
  ScrollArea,
  Typography,
} from '@v2/ui';

export function HotelDetailsDialog(props: PropertyDetails) {
  const {
    name,
    descriptions,
    phone,
    fax,
    checkin: { beginTime, endTime, checkout },
    checkinInstructions,
    checkinSpecialInstructions,
    mandatoryFees,
    optionalFees,
    attributes,
    amenities,
    knowBeforeYouGo,
  } = props;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="link" className="p-0" size="md">
          View hotel details
        </Button>
      </DialogTrigger>
      <DialogContent
        showCloseButton={false}
        className="flex h-screen w-full flex-col gap-0 rounded-none p-0 md:grid md:h-auto md:max-w-[640px] md:rounded-xl"
      >
        <DialogHeader className="flex flex-row-reverse items-center justify-end gap-x-3 border-b border-gray-200 p-4 md:flex-row md:justify-between md:p-6">
          <DialogTitle
            className={'test-sm font-semibold text-gray-800 md:text-base'}
          >
            Hotel details
          </DialogTitle>

          <DialogClose>
            <Close className="text-gray-800 md:text-gray-500" />
          </DialogClose>
        </DialogHeader>
        <ScrollArea className="h-full md:max-h-[568px]">
          <div className="flex flex-col gap-y-6 p-4 md:p-6">
            <div>
              <Typography size="txl" className="font-bold">
                About {name}
              </Typography>
              <Typography
                size="tsm"
                className="mt-2 font-medium"
                dangerouslySetInnerHTML={{
                  __html: descriptions,
                }}
              ></Typography>
            </div>
            <div className="flex items-start gap-x-3">
              <InfoCircle
                pathProps={{ stroke: '#667085' }}
                className="flex-shrink-0"
              />
              <div className="flex flex-col gap-y-2">
                <Typography size="tmd" className="font-bold">
                  Info
                </Typography>
                <ul>
                  <li className="text-sm font-medium text-gray-800">{`Phone: ${phone || ''}`}</li>
                  <li className="text-sm font-medium text-gray-800">{`Fax: ${fax || ''}`}</li>
                  <li className="text-sm font-medium text-gray-800">{`Check-in hours: ${beginTime} - ${endTime}`}</li>
                  <li className="text-sm font-medium text-gray-800">{`Check-out: ${checkout}`}</li>
                </ul>
                {checkinInstructions && (
                  <p
                    className="text-xs font-medium text-gray-500"
                    dangerouslySetInnerHTML={{
                      __html: checkinInstructions,
                    }}
                  />
                )}
                {checkinSpecialInstructions && (
                  <p
                    className="text-xs font-medium text-gray-500"
                    dangerouslySetInnerHTML={{
                      __html: checkinSpecialInstructions,
                    }}
                  />
                )}
              </div>
            </div>
            {mandatoryFees && (
              <div className="flex items-start gap-x-3">
                <CurrencyDollar
                  pathProps={{ stroke: '#667085' }}
                  className="flex-shrink-0"
                />

                <div className="flex flex-col gap-y-2">
                  <Typography size="tmd" className="font-bold">
                    Mandatory Fees
                  </Typography>
                  <Typography
                    size="tsm"
                    className="mt-2 font-medium"
                    dangerouslySetInnerHTML={{
                      __html: mandatoryFees,
                    }}
                  ></Typography>
                </div>
              </div>
            )}

            {optionalFees && (
              <div className="flex items-start gap-x-3">
                <CurrencyDollar
                  pathProps={{ stroke: '#667085' }}
                  className="flex-shrink-0"
                />

                <div className="flex flex-col gap-y-2">
                  <Typography size="tmd" className="font-bold">
                    Optional Fees
                  </Typography>
                  <Typography
                    size="tsm"
                    className="mt-2 font-medium"
                    dangerouslySetInnerHTML={{
                      __html: optionalFees,
                    }}
                  ></Typography>
                </div>
              </div>
            )}
            {knowBeforeYouGo && (
              <div className="flex items-start gap-x-3">
                <AlertTriangle
                  pathProps={{ stroke: '#667085' }}
                  className="flex-shrink-0"
                />

                <div className="flex flex-col gap-y-2">
                  <Typography size="tmd" className="font-bold">
                    Know before you go
                  </Typography>
                  <Typography
                    size="tsm"
                    className="mt-2 font-medium"
                    dangerouslySetInnerHTML={{
                      __html: knowBeforeYouGo,
                    }}
                  ></Typography>
                </div>
              </div>
            )}
            {attributes?.pets?.length > 0 && (
              <div className="flex items-start gap-x-3">
                <DogBowl className="flex-shrink-0 text-gray-500" />
                <div className="flex flex-col gap-y-2">
                  <Typography size="tmd" className="font-bold">
                    Pets
                  </Typography>
                  <ul>
                    {attributes?.pets?.map((feature) => (
                      <li
                        className="text-sm font-medium text-gray-800"
                        key={feature}
                      >
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {/* OTHER FEATURES */}
            <div className="flex items-start gap-x-3">
              <Check
                pathProps={{ stroke: '#667085' }}
                className="flex-shrink-0"
              />
              <div className="flex flex-col gap-y-2">
                <Typography size="tmd" className="font-bold">
                  Other features
                </Typography>

                <ul>
                  {attributes?.general?.map((feature) => (
                    <li
                      className="text-sm font-medium text-gray-800"
                      key={feature}
                    >
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/*  EMD OF OTHER FEATURES */}

            {/* AMENETIES */}
            <div className="flex items-start gap-x-3">
              <List
                pathProps={{ stroke: '#667085' }}
                className="flex-shrink-0"
              />
              <div className="flex flex-col gap-y-2">
                <Typography size="tmd" className="font-bold">
                  All ameneties
                </Typography>

                <div className="flex flex-wrap items-start gap-2">
                  {amenities?.split('|').map((feature) => (
                    <div
                      className="text-md flex h-10 items-center justify-center rounded-lg border border-gray-300 bg-white px-3 font-medium text-gray-800"
                      key={feature}
                    >
                      {feature}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
