'use client';
import { ILocation, ILocationResult } from '@components/searchCity';
import { LeagueEvent } from '@interfaces/APITravel';
import { IFormValues } from '@interfaces/buildPackage';
import { getInternationalFullDate } from '@utils/dates';
import { getLatLng, searchLocation } from '@utils/location';
import {
  ArrowLeft,
  ArrowRight,
  Building05,
  Button,
  Calendar,
  CalendarIcon,
  Check,
  Close,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandLoading,
  Day,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
  typographyVariants
} from '@v2/ui';
import { MarkerPin01 } from '@v2/ui/Icon/MapsAndTravel';
import { cn, themeConfig } from '@v2/utils';
import { format, isSameMonth } from 'date-fns';
import { FormikProps } from 'formik';
import moment from 'moment-timezone';
import { useSearchParams } from 'next/navigation';
import React, { FC, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useDebounceValue } from 'usehooks-ts';
import { StepName } from '../BuildYourPackage';
import * as PackageCard from './PackageContainer';
import { RoomConfiguration } from './RoomConfigurator';
import { isHotelValid } from './util';

interface BuyHotelProps {
  open?: boolean;
  isEnabled: boolean;
  onAction: () => void;
  onCancel: () => void;
  formik: FormikProps<IFormValues>;
  loading: boolean;
  hotelLocation: ILocationResult | null | undefined;
  event?: LeagueEvent | null;
  setStep: (name: StepName, value: boolean) => void;
  isBuildPackageComplete: boolean;
}

export function BuyHotel({
  onAction,
  formik,
  loading,
  onCancel,
  open,
  hotelLocation,
  event,
  isEnabled,
  setStep,
  isBuildPackageComplete,
}: BuyHotelProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const searchParams = useSearchParams();
  const [currentMonth, setCurrentMonth] = useState(
    new Date(event?.occurs_at as string)
  ); // Initialize current month state

  const [date, setDate] = React.useState<DateRange | undefined>({
    from: moment(formik.values.hotelStartDate).tz('GMT').toDate(),
    to: moment(formik.values.hotelEndDate).tz('GMT').toDate(),
  });
  const [isCalendarOpen, setIscalendarOpen] = useState(false);
  const [isSkip, setIsSkip] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const packageType = searchParams!.get('packageType')!;
  useEffect(() => {
    if (open && !hasConfirmed && !isSkip) {
      setIsCollapsed(true);
      setHasConfirmed(false);
      setIsSkip(false);
    }
  }, [open]);

  const handleOnCancel = () => {
    setIsCollapsed(false);
    onCancel();
    setIsSkip(true);
    setDate({ from: undefined, to: undefined });
  };

  const handleContinue = () => {
    onAction();
    setIsCollapsed(false);
    setHasConfirmed(true);
    formik.submitForm();
  };
  const isDisabled = packageType === 'tickets' || (!loading && !hotelLocation);
  const isDone = isHotelValid(formik);

  const hotelStartDate = moment(formik.values.hotelStartDate)
    .tz('GMT')
    .toDate();
  const hotelEndDate = moment(formik.values.hotelEndDate).tz('GMT').toDate();

  const handleConfirmDateSelection = () => {
    if (date?.from && date.to) {
      formik.setFieldValue('hotelStartDate', date?.from);
      formik.setFieldValue('hotelEndDate', date?.to);
      setIscalendarOpen(false);
    }
  };

  const eventDate = event?.occurs_at_local
    ? getInternationalFullDate(event.occurs_at_local, undefined)
    : null;

  return (
    <div>
      <PackageCard.Card open={isCollapsed} disabled={isDisabled}>
        <PackageCard.Header
          loading={loading}
          disabled={isDisabled}
          onClick={() => {
            setIsCollapsed(!isCollapsed);
            isSkip && setIsSkip(false);
            hasConfirmed && setHasConfirmed(false);
          }}
          trigger={
            !isCollapsed &&
            (isSkip || hasConfirmed) && (
              <Button
                variant="secondary"
                className="w-max"
                onClick={() => {
                  if (isSkip) setStep('hotel', true);
                }}
              >
                {hasConfirmed && 'Edit'}
                {isSkip && 'Add hotel'}
              </Button>
            )
          }
        >
          {isSkip && <Close pathProps={{ stroke: '#FA2846' }} />}

          {hasConfirmed && <Check pathProps={{ stroke: '#079455' }} />}

          {!isSkip && !hasConfirmed && (
            <Building05
              pathProps={{
                stroke: isDisabled
                  ? themeConfig.theme.colors.gray[500]
                  : themeConfig.theme.colors.gray[800],
              }}
            />
          )}

          <Typography
            size="txl"
            className={cn('font-bold', isDisabled && 'text-gray-500')}
          >
            {packageType === 'packages' && !hotelLocation && !loading
              ? 'No hotel offers for this event.'
              : 'Hotel '}
          </Typography>
        </PackageCard.Header>

        {hasConfirmed && !isCollapsed && <Overview formik={formik} />}
        <PackageCard.ContentCollapse>
          <div className="space-y-4">
            <Typography size="tmd" className={'mb-3 font-semibold'}>
              Destination details
            </Typography>
            <div className="flex flex-col gap-y-4 md:flex-row md:gap-x-4">
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="firstName">Location</Label>
                <SearchCity formik={formik} />
              </div>
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="firstName">Check in/out</Label>
                <Popover
                  open={isCalendarOpen}
                  onOpenChange={(val) => setIscalendarOpen(val)}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant={'secondary'}
                      className="h-[56px] justify-start font-medium"
                    >
                      <CalendarIcon className="mr-3 text-gray-800" />

                      {hotelStartDate ? (
                        hotelEndDate ? (
                          <>
                            {format(
                              date?.from ?? new Date(hotelStartDate),
                              'LLL dd, y'
                            )}{' '}
                            -{' '}
                            {format(
                              date?.to ?? new Date(hotelEndDate),
                              'LLL dd, y'
                            )}
                          </>
                        ) : (
                          format(
                            date?.from ?? new Date(hotelStartDate),
                            'LLL dd, y'
                          )
                        )
                      ) : (
                        <span>Pick a date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      disabled={{ before: new Date() }}
                      month={currentMonth}
                      onMonthChange={(month) => setCurrentMonth(month)}
                      weekStartsOn={1}
                      initialFocus
                      mode="range"
                      modifiers={{
                        // @ts-ignore
                        event: eventDate,
                      }}
                      selected={date}
                      onSelect={(val) => {
                        setDate(val);
                      }}
                      components={{
                        IconLeft: ({ ...props }) => (
                          <ArrowLeft size="20" className="stroke-gray-800" />
                        ),
                        IconRight: ({ ...props }) => (
                          <ArrowRight size="20" className="stroke-gray-800" />
                        ),
                        Day: ({ ...props }) => <Day {...props} />,
                        Footer: ({ displayMonth }) => (
                          <CalendarFooter
                            displayMonth={displayMonth}
                            date={date}
                            setDate={setDate}
                            onConfirm={handleConfirmDateSelection}
                            currentMonth={currentMonth}
                          />
                        ),
                      }}
                      numberOfMonths={2}
                    />
                  </PopoverContent>
                </Popover>
              </div>
            </div>
          </div>
          <RoomConfiguration formik={formik} />
          <div className="flex flex-col-reverse items-center justify-end gap-x-4 gap-y-3 md:flex-row">
            <Button
              type="submit"
              onClick={handleContinue}
              disabled={!isDone}
              className="w-full md:w-max"
            >
              Search
            </Button>
          </div>
        </PackageCard.ContentCollapse>
      </PackageCard.Card>
      {/* <Divider /> */}
    </div>
  );
}

type SearchCityProps = {
  formik: FormikProps<IFormValues>;
};

const SearchCity: FC<SearchCityProps> = ({ formik }) => {
  const { setFieldValue, values } = formik;
  const [isOpen, setIsOpen] = React.useState(false);
  const { location } = values;

  const [found, setFound] = useState<string>('');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<ILocation[]>([]);

  const [debouncedValue] = useDebounceValue(search, 1500);

  const getAPIData = async (searchValue) => {
    setIsLoading(true);
    setIsOpen(true);
    const l = await searchLocation(searchValue);
    setList(l);
    setIsLoading(false);
  };

  useEffect(() => {
    if (debouncedValue.length < 3) return;
    getAPIData(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (location === undefined) {
      setSearch('');
      setFound('');
    } else {
      setFound(location?.name || '');
    }
  }, [location]);
  const onChangeHandler = (e) => {
    setFound('');
    setSearch(e.target.value);
  };

  const onSelectLocation = async (value) => {
    const fullName = value.Text.split(', ');
    getLatLng(value.PlaceId).then((res) => {
      setFieldValue('location', {
        id: value.PlaceId,
        name: value.Text,
        country_name: fullName[fullName.length - 1],
        latitude: res.Geometry.Point[1],
        longitude: res.Geometry.Point[0],
      });
    });
    setFound(value.description);
  };

  return (
    <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <PopoverTrigger asChild>
        <Input
          extent="lg"
          value={found || search}
          name="firstName"
          type="text"
          required
          startIcon={<MarkerPin01 className="text-black" />}
          onChange={onChangeHandler}
        />
      </PopoverTrigger>
      <PopoverContent
        className="popover-content-width-same-as-its-trigger bg-white p-0"
        sideOffset={1}
        align="start"
      >
        <Command>
          <CommandList>
            {isLoading && <CommandLoading>Loading…</CommandLoading>}

            {!isLoading && <CommandEmpty>No country found.</CommandEmpty>}
            <CommandGroup>
              {!isLoading &&
                list?.length > 0 &&
                list
                  .filter((x) => x.Text)
                  .filter((x) => x?.PlaceId)
                  .map((option) => (
                    <CommandItem
                      disabled={false}
                      className="px-4 py-3"
                      key={option.Text}
                      onSelect={(item) => {
                        onSelectLocation(option);
                        setFound('');
                        setIsOpen(false);
                      }}
                    >
                      <span
                        className={cn(
                          typographyVariants({ size: 'tmd' }),
                          'font-semibold text-gray-800'
                        )}
                      >
                        {option.Text}
                        {/* - {option.country} */}
                      </span>
                    </CommandItem>
                  ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

function Overview(props: { formik: FormikProps<IFormValues> }) {
  const {
    formik: { values },
  } = props;
  const numRooms = values.roomConfigurations.length;
  const numAdults = values.roomConfigurations.reduce(
    (acc, cur) => acc + cur.adults,
    0
  );
  const totalChildren = values.roomConfigurations.reduce(
    (acc, cur) => acc + cur.children.length,
    0
  );

  const numInfants = values.roomConfigurations.reduce(
    (acc, cur) => acc + cur.infants,
    0
  );
  const numChildren = totalChildren + numInfants;

  const formattedStartDate = format(
    moment(values.hotelStartDate).tz('GMT').toDate(),
    'MMMM d, yyyy'
  );
  const formattedEndDate = format(
    moment(values.hotelEndDate).tz('GMT').toDate(),
    'MMMM d, yyyy'
  );

  return (
    <div>
      <Typography size="tsm" className="mb-1 font-medium text-gray-500">
        Destination details
      </Typography>
      <Typography size="tsm" className="font-medium text-gray-800">
        {values.location!.name} <br />
        {`from ${formattedStartDate} to ${formattedEndDate}`}
        <br />
        {numRooms} Room, {numAdults} Adult{numAdults > 1 ? 's' : ''}
        {numChildren > 0 && (
          <>
            ,&nbsp;{numChildren} Child{numChildren > 1 ? 'ren' : ''}
          </>
        )}
      </Typography>
    </div>
  );
}

type CalendarFooterProps = {
  date: DateRange | undefined;
  setDate: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
  onConfirm: () => void;
  displayMonth: Date | undefined;
  currentMonth: Date;
};

function CalendarFooter({
  setDate,
  date,
  onConfirm,
  displayMonth,
  currentMonth,
}: CalendarFooterProps) {
  if (!displayMonth) return null;
  const isFirstMonth = isSameMonth(displayMonth, currentMonth);

  return (
    <tfoot className="flex items-center justify-between py-2">
      {isFirstMonth && (
        <Button
          variant="tertiary"
          className="text-gray-800"
          onClick={() => setDate({ from: undefined, to: undefined })}
        >
          Reset
        </Button>
      )}
      {!isFirstMonth && (
        <div className="flex w-full justify-end space-x-3">
          <Button
            variant="secondary"
            onClick={() =>
              setDate({
                from: undefined,
                to: undefined,
              })
            }
          >
            Cancel
          </Button>
          <Button disabled={!date?.from && !date?.to} onClick={onConfirm}>
            Apply
          </Button>
        </div>
      )}
    </tfoot>
  );
}
