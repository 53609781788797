'use client';
import { PropertyDetails } from '@interfaces/expedia/property';
import { v2Links } from '@utils/navigation/links';
import { Building05, Button, ChevronLeft, Typography } from '@v2/ui';
import Link from 'next/link';
import pluralize from 'pluralize';
import { useEffect, useRef, useState } from 'react';

import { simpleRenderToString } from '@v2/utils/simple-render-to-string';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import { useCurrencyContext } from '@contexts/currency-context';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';
import { useBuildPackagesStore } from '@store/build-package-store';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { StarRatings } from '@v2/components/StarRatings';
import { cn } from '@v2/utils';
import { HOTEL_AMENETIES } from '@v2/views/SearchHotel/components/data';
import { differenceInCalendarDays } from 'date-fns';
import format from 'date-fns/format';
import moment from 'moment-timezone';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import { useMediaQuery } from 'usehooks-ts';
import { HotelDetailsDialog } from './HotelDetailsDialog';
import { HotelImagesDialog } from './HotelImagesDialog';
const apiKey = process.env.MAPS_API_KEY;
const region = 'us-east-1';
const mapName = 'explore.map';

type Props = {
  propertyDetails: PropertyDetails;
};

const mapStyle = {
  background: 'var(--ui-background-booking)',
};

export function HotelOverview({ propertyDetails }: Props) {
  const {
    name,
    descriptions,
    rating,
    amenities,
    image,
    images,
    minimumTotalPrice,
  } = propertyDetails;

  const { endDate, nights, startDate, rooomsLength } = useBuildPackagesStore(
    (state) => ({
      startDate: moment(state.hotelStartDate).tz('GMT').toDate(),
      endDate: moment(state.hotelEndDate).tz('GMT').toDate(),
      nights: differenceInCalendarDays(
        moment(state.hotelEndDate).tz('GMT').toDate(),
        moment(state.hotelStartDate).tz('GMT').toDate()
      ),

      rooomsLength: state.roomConfigurations.length,
    })
  );

  const { priceRate, currency } = useCurrencyContext();

  const handleScrollToRooms = () => {
    const targetElement = document.getElementById('roomsSection');
    if (targetElement) {
      const topOffset = targetElement.offsetTop;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  const { packageGuests, minTicketPrice, isBundle } = useBuildPackageBundle();

  const basePrice = isBundle
    ? minTicketPrice + minimumTotalPrice
    : minimumTotalPrice;

  const searchParams = useSearchParams();

  const hotelStartDate = moment(startDate).tz('GMT').toDate();
  const hotelEndDate = moment(endDate).tz('GMT').toDate();
  const isTablet = useMediaQuery('(min-width: 768px)');

  return (
    <div className="mt-4 w-full rounded-xl border border-gray-200 bg-white md:mt-5">
      <div className="flex items-center justify-between border-b border-gray-200 p-4 md:p-8">
        <Link
          href={{
            pathname: v2Links.searchHotel,
            query: {
              eventId: searchParams?.get('eventId'),
            },
          }}
          className="flex items-center"
        >
          <ChevronLeft
            size="20"
            pathProps={{ stroke: '#667085' }}
            className="mr-2"
          />
          <Typography size={isTablet ? 'tmd' : 'tsm'} className="text-gray-500">
            All properties
          </Typography>
        </Link>

        <Button onClick={handleScrollToRooms} size={isTablet ? 'md' : 'lg'}>
          View rooms
        </Button>
      </div>

      <div className="flex flex-col-reverse md:flex-col">
        <div className="flex flex-col items-center justify-between space-y-8 px-4 py-6 md:flex-row md:p-8">
          <div className="flex flex-col items-start justify-start gap-y-5 md:gap-y-6">
            <div>
              <h2 className="mb-3 text-2xl font-bold md:text-4xl md:tracking-[-0.72px]">
                {name}
              </h2>
              <StarRatings active={rating} count={5} />
            </div>
            <p className="text-xs font-medium first-line:max-w-[68ch] md:text-sm">
              {descriptions[0]}
            </p>
            <div className="mb-3 flex w-[90%] flex-wrap items-center justify-start gap-x-5 gap-y-3">
              {HOTEL_AMENETIES.map((item) => {
                if (amenities.toLowerCase().includes(item.value.toLowerCase()))
                  return (
                    <div className="flex items-center gap-1.5" key={item.label}>
                      {item.icon}
                      <Typography
                        size={isTablet ? 'tsm' : 'txs'}
                        className="font-medium text-gray-500"
                      >
                        {item.label}
                      </Typography>
                    </div>
                  );
              })}
            </div>
            <HotelDetailsDialog {...propertyDetails} />
          </div>
          <HotelMapLocation {...propertyDetails} />
        </div>
        <HotelMainImages mainImage={image} images={images} />
      </div>

      <div className="md:borer-none flex flex-col items-start justify-between space-y-6 border-t border-gray-200 px-4 py-6 md:flex-row md:items-center md:p-8">
        <div className="flex flex-col gap-y-1.5">
          <Typography size="tlg" className="font-bold">
            Price details{' '}
          </Typography>
          <p className="text-xs font-medium md:text-sm">
            {format(new Date(hotelStartDate), 'MMM d, yyyy')} -{' '}
            {format(new Date(hotelEndDate), 'MMM d, yyyy')} ({nights}{' '}
            {pluralize('night', nights)}) <br />
            Includes taxes & fees
          </p>
        </div>

        <div className="flex flex-col items-start gap-y-1.5 md:items-end">
          <Typography size={isTablet ? 'dmd' : 'dsm'} className="font-bold">
            {formatCurrencyV2(
              basePrice / packageGuests,
              priceRate,
              currency,
              false,
              false
            )}
          </Typography>
          <p className="text-xs font-medium text-gray-500 md:text-sm">
            Per traveler (includes {rooomsLength}{' '}
            {pluralize('room', rooomsLength)})
          </p>
        </div>
      </div>
    </div>
  );
}

function HotelMapLocation(props: PropertyDetails) {
  const { latitude, longitude, fullAddress } = props;

  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<any>(null);
  const [zoom] = useState(11);
  const isTablet = useMediaQuery('(min-width: 768px)');

  const drawMap = async () => {
    if (map.current) map.current.remove();
    map.current = new maplibregl.Map({
      container: mapContainer.current ?? '',
      style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
      center: [longitude, latitude],
      zoom,
    });

    const eventMarker = document.createElement('div');
    eventMarker.innerHTML = await simpleRenderToString(
      <div className="relative rounded-lg bg-white p-2 shadow-md">
        <Building05 />
        <MapArrow />
        <div className="absolute -bottom-[20px] left-0 right-0 shadow-md" />
      </div>
    );

    new maplibregl.Marker({ element: eventMarker })
      .setLngLat([longitude, latitude])
      .addTo(map.current);
  };

  useEffect(() => {
    drawMap();
  }, [latitude, longitude]);

  return (
    <div className="w-full flex-shrink-0 md:w-[420px]">
      <div
        ref={mapContainer}
        style={mapStyle}
        className={cn('h-[180px] w-full rounded-lg')}
      />

      <div className="mt-4">
        <Typography size={isTablet ? 'tmd' : 'tsm'} className="font-medium">
          {fullAddress}
        </Typography>
        <Typography size="tsm" className="mt-2 text-gray-500">
          {/* {distance.toFixed(1)} mi from address */}
        </Typography>
      </div>
    </div>
  );
}

function HotelMainImages(props: { mainImage: string; images: string[] }) {
  const { mainImage, images } = props;
  const isTablet = useMediaQuery('(min-width: 768px)');

  if (!isTablet)
    return (
      <div className="relative min-h-[200px] w-full">
        <Image
          src={mainImage}
          className="object-cover"
          alt="Room photo"
          fill={true}
        />
      </div>
    );

  return <HotelImagesDialog images={images} />;
}

export function MapArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      className="absolute -bottom-[7px] left-1/2 -translate-x-1/2 transform"
    >
      <path
        d="M14.0711 0.485289C14.962 0.485289 15.4081 1.56243 14.7782 2.1924L8.70711 8.26347C8.31658 8.654 7.68342 8.654 7.29289 8.26347L1.22183 2.1924C0.591867 1.56243 1.03803 0.485289 1.92894 0.485289L14.0711 0.485289Z"
        fill="white"
      />
    </svg>
  );
}
