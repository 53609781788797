import { IFormValues } from '@interfaces/buildPackage';
import { Button, Plus, Trash02, Typography } from '@v2/ui';
import { themeConfig } from '@v2/utils';
import { FormikProps } from 'formik';
import { FC } from 'react';

interface PaxCounterProps {
  value: number;
  onIcrement: () => void;
  onDecrement: () => void;
}

export const PaxCounter: FC<PaxCounterProps> = ({
  value,
  onIcrement,
  onDecrement,
}) => {
  return (
    <div className="flex items-center justify-start gap-x-2">
      <Button
        size="sm"
        variant="secondary"
        onClick={onDecrement}
        className="h-[20px] w-[20px] rounded-[5.333px]"
        type="button"
      >
        -
      </Button>
      <Typography size="tmd" className="min-w-[16px] text-center font-medium">
        {value}
      </Typography>
      <Button
        type="button"
        size="sm"
        variant="secondary"
        onClick={onIcrement}
        className="h-[20px] w-[20px] rounded-[5.333px]"
      >
        +
      </Button>
    </div>
  );
};

type RoomsConfigruationProps = {
  formik: FormikProps<IFormValues>;
};

export function RoomConfiguration(props: RoomsConfigruationProps) {
  const { formik } = props;
  const { setFieldValue, values: formikValues } = formik;

  const handleAddRoom = () => {
    const { roomConfigurations } = formik.values;
    formik.setFieldValue('roomConfigurations', [
      ...roomConfigurations,
      {
        adults: 1,
        children: [],
        infants: 0,
      },
    ]);
  };

  return (
    <div className="space-y-3">
      <div className="flex items-center justify-between">
        <Typography size="tmd" className={'font-semibold'}>
          Room
        </Typography>

        <Button
          disabled={formikValues.roomConfigurations.length > 8}
          type="button"
          variant="link"
          className="inline-flex p-0 md:hidden"
          onClick={handleAddRoom}
        >
          <Plus className="mr-1 text-primary-500" />
          Add room
        </Button>
      </div>
      <div className="flex flex-wrap gap-4">
        {formikValues.roomConfigurations?.map((configuration, roomIndex) => (
          <div
            key={roomIndex}
            className="h-[228px] w-full space-y-1.5 rounded-lg border border-gray-300 p-4 md:h-[240px] md:w-[240px]"
          >
            <div className="flex items-center justify-between">
              <Typography
                variant="h3"
                size="tsm"
                className="font-medium text-gray-500"
              >{`Room ${roomIndex + 1}`}</Typography>
              <div
                style={{ cursor: 'pointer' }}
                role="button"
                tabIndex={0}
                onClick={() => {
                  const rooms = [...formikValues.roomConfigurations];
                  rooms.splice(roomIndex, 1);
                  setFieldValue('roomConfigurations', rooms);
                }}
              >
                <Trash02 size="20" className="text-gray-800" />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <Typography
                size="tmd"
                variant="h4"
                className="flex flex-col font-medium"
              >
                Adults
                <Typography size="txs" className="mb-1 text-gray-500">
                  Age over 17
                </Typography>
              </Typography>
              {/* eslint-disable-next-line max-len */}
              <PaxCounter
                value={configuration.adults}
                onIcrement={() => {
                  setFieldValue(
                    `roomConfigurations.${roomIndex}.adults`,
                    configuration.adults + 1
                  );
                }}
                onDecrement={() => {
                  if (configuration.adults > 1) {
                    setFieldValue(
                      `roomConfigurations.${roomIndex}.adults`,
                      configuration.adults - 1
                    );
                  }
                }}
              />
            </div>
            <div className="flex items-center justify-between">
              <Typography
                size="tmd"
                variant="h4"
                className="flex flex-col font-medium"
              >
                Children
                <Typography size="txs" className="mb-1 text-gray-500">
                  Age 2-17
                </Typography>
              </Typography>
              {/* eslint-disable-next-line max-len */}
              <PaxCounter
                value={configuration.children.length}
                onIcrement={() => {
                  const { children } =
                    formikValues?.roomConfigurations?.[roomIndex];
                  setFieldValue(`roomConfigurations.${roomIndex}.children`, [
                    ...children,
                    0,
                  ]);
                }}
                onDecrement={() => {
                  const children = [
                    ...formikValues?.roomConfigurations?.[roomIndex].children,
                  ];
                  children.pop();
                  setFieldValue(
                    `roomConfigurations.${roomIndex}.children`,
                    children
                  );
                }}
              />
            </div>

            <div className="flex items-center justify-between">
              <Typography
                size="tmd"
                variant="h4"
                className="flex flex-col font-medium"
              >
                Infants
                <Typography size="txs" className="mb-1 text-gray-500">
                  Age under 2
                </Typography>
              </Typography>
              {/* eslint-disable-next-line max-len */}
              <PaxCounter
                value={configuration.infants}
                onIcrement={() => {
                  setFieldValue(
                    `roomConfigurations.${roomIndex}.infants`,
                    configuration.infants + 1
                  );
                }}
                onDecrement={() => {
                  if (configuration.infants - 1 !== -1)
                    setFieldValue(
                      `roomConfigurations.${roomIndex}.infants`,
                      configuration.infants - 1
                    );
                }}
              />
            </div>
          </div>
        ))}
        <div className="hidden h-[240px] w-[240px] items-center justify-center rounded-lg border border-dashed md:flex">
          <Button
            type="button"
            variant="link"
            className="p-0"
            onClick={handleAddRoom}
          >
            + Add new room
          </Button>
        </div>
      </div>
    </div>
  );
}
