import { NEXT_STEPS } from '@config/index';
import {
  PACKAGE_STORAGE,
  SELECTED_TICKET_STORAGE,
  SOCIAL_LOGIN_REDIRECT,
} from '@config/storageKeys';
import useStorage from '@hooks/useStorage';
import { links } from '@utils/navigation/links';
import { useRouter } from 'next/navigation';

export default function useNext(page?: string) {
  const { push } = useRouter();
  const storage = useStorage();

  return () => {
    const { selected } =
      (typeof window !== 'undefined' &&
        storage.getItem(PACKAGE_STORAGE) &&
        JSON.parse(storage.getItem(PACKAGE_STORAGE) as string)) ??
      {};

    if (selected) {
      const { hotel, tickets } = selected;
      if (page === NEXT_STEPS.checkout && hotel) {
        push(links.searchHotel);
      } else if ((page === NEXT_STEPS.hotel || !hotel) && tickets) {
        const selectedTicketData = storage.getItem(SELECTED_TICKET_STORAGE);
        if (selectedTicketData) {
          storage.setItem(SOCIAL_LOGIN_REDIRECT, links.checkout);
          push(links.checkout);
        } else {
          push(links.searchTickets);
        }
      } else if (page) {
        storage.setItem(SOCIAL_LOGIN_REDIRECT, links.checkout);
        push(links.checkout);
      }
    }
  };
}
