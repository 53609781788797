import {
  ChevronLeft,
  ChevronRight,
  Dialog,
  DialogContent,
  DialogTrigger,
  Image03,
  Typography,
} from '@v2/ui';
import { cn } from '@v2/utils';
import Image from 'next/image';
import { useRef, useState } from 'react';
import Masonry from 'react-responsive-masonry';
import { useOnClickOutside } from 'usehooks-ts';

type Props = {
  images: string[];
};

export function HotelImagesDialog(props: Props) {
  const { images } = props;

  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const handleDecrement = () => {
    if (currentStep === 0) return;

    setCurrentStep((state) => state - 1);
  };

  const handleIncrement = () => {
    if (currentStep === images.length - 1) return;

    setCurrentStep((state) => state + 1);
  };

  const openImageByIndex = (index: number) => {
    setIsOpen(true);
    setCurrentStep(index);
  };
  useOnClickOutside(imageContainerRef, () => setIsOpen(false));
  if (images.length < 5) {
    return (
      <Masonry gutter="10px" columnsCount={2}>
        {images.slice(0, 3).map((image, i) => (
          <Image
            alt="Hotel other photos"
            src={image}
            key={image}
            width="0"
            height="0"
            sizes="637px"
            loading="lazy"
            className={cn('h-[147px] w-full object-cover object-center')}
          />
        ))}
      </Masonry>
    );
  }
  return (
    <>
      <div className="flex min-h-[300px] items-center gap-x-2.5">
        <div className="relative mb-3 h-full w-3/6 flex-shrink-0 rounded-lg bg-cover">
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild onClick={() => setCurrentStep(0)}>
              <button className="absolute left-6 top-6 flex h-10 items-center gap-x-2.5 rounded-lg bg-white px-3">
                <Image03 className="text-gray-800" />
                <Typography size="tsm" className="font-semibold">
                  {images.length}+
                </Typography>
              </button>
            </DialogTrigger>

            <DialogContent
              showCloseButton={false}
              className="md:max-w-screen h-screen w-screen items-center justify-center gap-0 rounded-none bg-transparent p-0 md:grid"
            >
              <div ref={imageContainerRef}>
                <div className="absolute left-4 top-1/2 z-10 flex h-full min-h-fit -translate-y-1/2 transform items-center">
                  <button
                    className="flex h-12 w-12 items-center justify-center rounded-full bg-white text-gray-800 shadow-md disabled:hidden"
                    onClick={handleDecrement}
                  >
                    <ChevronLeft />
                  </button>
                </div>
                <div className="flex items-center justify-center md:h-[720px] md:max-w-[1080px]">
                  <Image
                    src={images[currentStep]}
                    alt={'Photo of the hotel'}
                    width="0"
                    height="0"
                    sizes="1080"
                    loading="lazy"
                    className="h-[720px] w-full object-cover object-center"
                  />
                </div>
                <div className="absolute right-4 top-1/2 z-10 flex h-full min-h-fit w-12 -translate-y-1/2 transform items-center">
                  <button
                    className="flex h-12 w-12 items-center justify-center rounded-full bg-white text-gray-800 shadow-md disabled:opacity-50 lg:mr-[-20px]"
                    onClick={handleIncrement}
                  >
                    <ChevronRight />
                  </button>
                </div>
                <Typography
                  size="tsm"
                  className="absolute bottom-4 left-0 right-0 z-0 mb-4 flex items-end justify-center font-semibold text-white"
                >
                  Picture {currentStep + 1} of {images.length}
                </Typography>
              </div>
            </DialogContent>
          </Dialog>
          <Image
            src={images[0]}
            alt={'main Hotel photo'}
            width="0"
            height="0"
            sizes="637px"
            loading="lazy"
            className="h-[300px] w-full cursor-pointer object-cover object-center"
            onClick={() => openImageByIndex(0)}
          />
        </div>
        <Masonry gutter="10px" columnsCount={2}>
          {images.slice(1, 5).map((image, i) => (
            <Image
              alt="Hotel other photos"
              src={image}
              key={image}
              width="0"
              height="0"
              sizes="637px"
              loading="lazy"
              className={cn(
                'h-[147px] w-full cursor-pointer object-cover object-center'
              )}
              onClick={() => openImageByIndex(i + 1)}
            />
          ))}
        </Masonry>
      </div>
    </>
  );
}
