import type { LeagueEvent } from '@interfaces/APITravel';
import {
  SearchHotelState,
  useSearchHotelStore,
} from '@store/search-hotel-store';
import {
  Button,
  Close,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Edit02,
  FilterFunnel01,
  Map02,
  typographyVariants,
} from '@v2/ui';
import { cn } from '@v2/utils';
import { useFormik, type FormikProps } from 'formik';
import React from 'react';
import {
  DistanceToVenueField,
  HotelFeatureField,
  PriceTravelerRangeField,
  QueryField,
  RatingField,
} from '../Filters';
import {
  BaseCheckInOutFilter,
  BaseLocationFilter,
  BaseRoomsFilter,
} from './BaseFilters';

import { useLockBodyScroll } from '@hooks/use-lock-body-scroll';
import { format } from 'date-fns';
import { invalidatePropertySearch } from '../../util';
import type { HeaderFormikProps } from './Header';
import { useWindowSize } from '@uidotdev/usehooks';

type Props = {
  event: LeagueEvent;
  formik: FormikProps<HeaderFormikProps>;
};

export function MobileFilter(props: Props) {
  return (
    <div className="my-4 mt-4 flex w-full items-center gap-x-3 px-4 md:mt-5">
      <SortAndFilterDialog {...props} />
      <Button
        variant="secondary"
        className="w-full bg-white"
        onClick={() => {
          useSearchHotelStore.setState({ isMapFullScreen: true });
        }}
      >
        <Map02 className="mr-2" />
        Map view
      </Button>
    </div>
  );
}

function SortAndFilterDialog(props: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  useLockBodyScroll(isOpen);
  const setHotelFilters = useSearchHotelStore((state) => state.setHotelFilters);
  const hotelFilters = useSearchHotelStore((state) => state.hotelFilters);
  const { height } = useWindowSize();
  const formik = useFormik<SearchHotelState['hotelFilters']>({
    initialValues: {
      ...hotelFilters,
    },
    onSubmit: (val) => {
      setIsOpen(false);
      setHotelFilters({ ...val });

      invalidatePropertySearch();
    },
  });
  const [isBuildPackageFilter, setIsBuildPackageFilter] = React.useState(false);

  const formHeight = height >= 800 ? '100%' : '80%';
  return (
    <Dialog open={isOpen} onOpenChange={(val) => setIsOpen(val)} modal={false}>
      <DialogTrigger asChild>
        <Button className="w-full bg-white" variant="secondary">
          <FilterFunnel01 className="mr-2" />
          Sort & Filter
        </Button>
      </DialogTrigger>

      <DialogContent
        showCloseButton={false}
        className="flex h-screen w-full flex-col rounded-none p-0 md:w-screen md:max-w-[100vw]"
      >
        <DialogHeader className="border-b border-gray-300">
          <div className="flex items-center justify-start gap-x-3 p-4">
            {/* <DialogClose className="m-0"> */}
            <button
              onClick={() => {
                if (isBuildPackageFilter) {
                  setIsBuildPackageFilter(false);
                  return;
                }

                setIsOpen(false);
              }}
            >
              <Close className="text-gray-800" />
            </button>
            {/* </DialogClose> */}
            <DialogTitle
              className={cn(
                typographyVariants({ size: 'tsm' }),
                'font-bold md:font-semibold'
              )}
            >
              Filters
            </DialogTitle>
          </div>
        </DialogHeader>

        {!isBuildPackageFilter && (
          <>
            <form
              onSubmit={formik.handleSubmit}
              className="flex h-full flex-col p-4"
            >
              <div
                className="no-scrollbar overflow-auto"
                style={{
                  height: formHeight,
                }}
              >
                <button
                  onClick={() => setIsBuildPackageFilter(true)}
                  className="mb-6 flex h-[72px] w-full items-start justify-between rounded-xl border border-gray-300 px-4 py-3"
                >
                  <div className="flex flex-col text-start">
                    <span
                      className={cn(
                        `${typographyVariants({ size: 'tsm' })}`,
                        'w-[25ch] truncate font-medium'
                      )}
                    >
                      {props.formik.values.location?.name}
                    </span>

                    <span
                      className={cn(
                        `${typographyVariants({ size: 'tsm' })}`,
                        'font-medium text-gray-500'
                      )}
                    >
                      {format(
                        new Date(props.formik.values.hotelStartDate),
                        'LLL dd, y'
                      )}{' '}
                      -{' '}
                      {format(
                        new Date(props.formik.values.hotelEndDate),
                        'LLL dd, y'
                      )}
                      , {props.formik.values.roomConfigurations.length}
                    </span>
                  </div>

                  <Edit02
                    className="text-gray-500"
                    pathProps={{ stroke: 'currentColor' }}
                  />
                </button>
                <div className="relative flex-1 space-y-6">
                  <QueryField
                    value={formik.values.query}
                    label="Property name"
                    onValueChange={(value) =>
                      formik.setFieldValue('query', value)
                    }
                  />
                  <RatingField
                    value={formik.values.rating}
                    onValueChange={(val) => formik.setFieldValue('rating', val)}
                  />
                  <HotelFeatureField
                    value={formik.values.featureHotel}
                    onValueChange={(val) =>
                      formik.setFieldValue('featureHotel', val)
                    }
                  />
                  <PriceTravelerRangeField
                    value={formik.values.pricePerTraveler}
                    onValueChange={(val) =>
                      formik.setFieldValue('pricePerTraveler', val)
                    }
                  />
                  <DistanceToVenueField
                    value={formik.values.distance}
                    onValueChange={(val) =>
                      formik.setFieldValue('distance', val)
                    }
                  />
                </div>

                <div className="pb-12" />
              </div>

              <DialogFooter className="w-full flex-row gap-x-4">
                <Button
                  className="w-full"
                  variant="secondary"
                  type="button"
                  onClick={() => formik.resetForm()}
                >
                  Clear
                </Button>
                <Button className="w-full" type="submit">
                  Apply
                </Button>
              </DialogFooter>
            </form>
          </>
        )}

        {isBuildPackageFilter && (
          <form
            onSubmit={(e) => {
              props.formik.handleSubmit(e);
              setIsOpen(false);
            }}
            className="flex h-full flex-col"
          >
            <div className="flex flex-1 flex-col gap-y-4 p-4">
              <BaseLocationFilter formik={props.formik} />
              <BaseCheckInOutFilter formik={props.formik} event={props.event} />
              <BaseRoomsFilter formik={props.formik} />
            </div>

            <DialogFooter className="justfy-stretch p-4">
              <Button className="w-full" type="submit">
                Search
              </Button>
            </DialogFooter>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
}
``;
