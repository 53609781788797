'use client';
import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import { links } from '@utils/navigation/links';
import { Button, Typography } from '@v2/ui';
import { useRouter } from 'next/navigation';

type Props = {};

export function NotFoundView({}: Props) {
  const isTablet = useBetterMediaQuery('(min-width: 768px)');
  const { push } = useRouter();

  return (
    <div className="mx-auto w-full max-w-[720px]">
      <div className="flex flex-col items-center px-4 md:px-0">
        <Vector size={isTablet ? '80' : '56'} />
        <Typography
          size="dmd"
          className="mb-4 text-center font-bold"
        >{`Whoops, something went wrong.`}</Typography>
        <div className="mb-8 text-center">
          <Typography size="tmd" className="mb-2 font-medium">
            Whoops, something went wrong.
          </Typography>
        </div>
        <Button
          variant="secondary"
          onClick={() => {
            push(links.home);
          }}
        >
          Go to homepage
        </Button>
      </div>
    </div>
  );

  function Vector({ size }: { size: string }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={80}
        viewBox="0 0 80 80"
        fill="none"
      >
        <path
          d="M44.169 64.6375H1.71875V5.625H75.1564V60.4668"
          stroke="#1D2939"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M74.3751 12.6562H31.4062L25.9374 19.6875H2.5"
          stroke="#1D2939"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.75 12.6562H8.75109"
          stroke="#1D2939"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.7812 12.6562H15.7825"
          stroke="#1D2939"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.8125 12.6562H22.8138"
          stroke="#1D2939"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.53125 49.1934H12.8334M20.0825 49.1934H39.6119"
          stroke="#1D2939"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.53125 56.2246H31.5597"
          stroke="#1D2939"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M67.3454 35.1309H47.4258"
          stroke="#1D2939"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M67.3455 28.0996H63.1122M56.1413 28.0996H47.4258"
          stroke="#1D2939"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M71.7014 74.3749L50.4301 74.3657C45.374 74.3635 42.3598 68.8761 44.807 64.6455L55.3458 46.4262C57.9036 42.0044 64.2934 42.0075 66.9122 46.5394L77.4013 64.6921C79.9619 69.1232 76.578 74.3771 71.7014 74.3749Z"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M61.1133 52.5V61.0938"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M61.1133 67.3438H61.1144"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="2.6131"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.0011 35.1304H9.53125L10.3117 28.0996M15.7816 28.0998V40.5998"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.7656 28.0996C26.4844 28.0996 27.8906 29.5059 27.8906 31.2246V37.4746C27.8906 39.1934 26.4844 40.5996 24.7656 40.5996C23.0469 40.5996 21.6406 39.1934 21.6406 37.4746V31.2246C21.6406 29.5057 23.0469 28.0996 24.7656 28.0996Z"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.8331 35.1304H33.3633L34.1438 28.0996M39.6138 28.0998V40.5998"
          stroke="#FA2846"
          stroke-width="3"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
}
