import type { TicketGroup } from '@interfaces/ticketevolution/tikets';
import { useTicketsStore } from '@store/tickets-store';
import { Typography } from '@v2/ui';
import { Slider, SliderRange, SliderThumb, SliderTrack } from '@v2/ui/Slider';
import { startTransition, useEffect, useMemo } from 'react';

export function TicketPriceRangeSlider({
  ticketsList,
}: {
  ticketsList: TicketGroup[];
}) {
  const [priceRange, setPriceRange] = useTicketsStore((state) => [
    state.priceRange,
    state.setPriceRange,
  ]);
  const ticketRangePrice = useMemo(() => {
    const temp = { min: 0, max: 100 };
    if (ticketsList.length) {
      const priceArray: number[] = [];
      ticketsList.forEach(({ retail_price }) => {
        if (typeof retail_price === 'number') {
          priceArray.push(retail_price);
        }
      });
      const minRange = Math.floor(Math.min(...priceArray));
      const maxRange = Math.ceil(Math.max(...priceArray));

      temp.min = minRange;
      temp.max = maxRange;
    }
    return temp;
  }, [ticketsList]);

  useEffect(() => {
    setPriceRange([ticketRangePrice.min, ticketRangePrice.max]);
  }, [ticketRangePrice.max, ticketRangePrice.min]);
  return (
    <div>
      <Typography
        size="tmd"
        className="mb-1.5 flex items-center justify-between font-medium"
      >
        <span>${ticketRangePrice.min}</span>
        <span>${ticketRangePrice.max}</span>
      </Typography>
      <Slider
        min={ticketRangePrice.min}
        max={ticketRangePrice.max}
        minStepsBetweenThumbs={10}
        value={priceRange}
        onValueChange={(val) => {
          startTransition(() => {
            setPriceRange(val);
          });
        }}
      >
        <SliderTrack>
          <SliderRange />
        </SliderTrack>
        <SliderThumb />
        <SliderThumb />
      </Slider>
    </div>
  );
}
