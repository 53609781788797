import {
  ArrowLeft,
  ArrowRight,
  Button,
  Calendar,
  CalendarIcon,
  Day,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@v2/ui';
import { format, isSameMonth } from 'date-fns';
import { FormikProps } from 'formik';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';

import type { LeagueEvent } from '@interfaces/APITravel';
import { useMediaQuery } from 'usehooks-ts';
import type { HeaderFormikProps } from '../Header';
import { InputWithLabel } from './InputWithLabel';
import { getInternationalFullDate } from '@utils/dates';
import moment from 'moment-timezone';

type Props = {
  event: LeagueEvent;
  formik: FormikProps<HeaderFormikProps>;
};

export function BaseCheckInOutFilter(props: Props) {
  const {
    event,
    formik: { setFieldValue, values },
  } = props;

  const hotelStartDate = moment(values.hotelStartDate).tz('GMT').toDate();
  const hotelEndDate = moment(values.hotelEndDate).tz('GMT').toDate();
  const [currentMonth, setCurrentMonth] = useState(
    new Date(event?.occurs_at as string)
  );

  const [date, setDate] = useState<DateRange | undefined>({
    from: hotelStartDate,
    to: hotelEndDate,
  });
  const [isCalendarOpen, setIscalendarOpen] = useState(false);

  const checkInOutValue = hotelStartDate
    ? hotelEndDate
      ? `${format(hotelStartDate, 'LLL dd, y')} - ${format(hotelEndDate, 'LLL dd, y')}`
      : format(hotelStartDate, 'LLL dd, y')
    : 'Pick a date';

  const handleConfirmDateSelection = () => {
    if (date?.from && date.to) {
      setFieldValue('hotelStartDate', date?.from);
      setFieldValue('hotelEndDate', date?.to);
      setIscalendarOpen(false);
    }
  };

  const matches = useMediaQuery('(min-width: 768px)');

  const numberOfMonths = matches ? 2 : 1;

  const eventDate = event?.occurs_at_local
    ? getInternationalFullDate(event.occurs_at_local, undefined)
    : null;

  return (
    <>
      <Popover
        open={isCalendarOpen}
        onOpenChange={(val) => setIscalendarOpen(val)}
      >
        <PopoverTrigger asChild>
          <InputWithLabel
            icon={
              <CalendarIcon
                className="text-gray-800"
                pathProps={{ stroke: 'currentColor' }}
              />
            }
            label={'Check in/out'}
            value={checkInOutValue}
          />
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            numberOfMonths={numberOfMonths}
            disabled={{ before: new Date() }}
            month={currentMonth}
            onMonthChange={(month) => setCurrentMonth(month)}
            weekStartsOn={1}
            initialFocus
            mode="range"
            selected={date}
            modifiers={{
              // @ts-ignore
              event: eventDate,
            }}
            onSelect={(val) => {
              setDate(val);
            }}
            components={{
              IconLeft: ({ ...props }) => (
                <ArrowLeft size="20" className="stroke-gray-800" />
              ),
              IconRight: ({ ...props }) => (
                <ArrowRight size="20" className="stroke-gray-800" />
              ),
              Day: ({ ...props }) => <Day {...props} />,
              Footer: ({ displayMonth }) => (
                <CalendarFooter
                  displayMonth={displayMonth}
                  date={date}
                  setDate={setDate}
                  onConfirm={handleConfirmDateSelection}
                  currentMonth={currentMonth}
                  numberOfMonths={numberOfMonths}
                />
              ),
            }}
          />
        </PopoverContent>
      </Popover>
    </>
  );
}

type CalendarFooterProps = {
  date: DateRange | undefined;
  setDate: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
  onConfirm: () => void;
  displayMonth: Date | undefined;
  currentMonth: Date;
  numberOfMonths: number;
};

function CalendarFooter({
  setDate,
  date,
  onConfirm,
  displayMonth,
  currentMonth,
  numberOfMonths,
}: CalendarFooterProps) {
  if (!displayMonth) return null;
  const isFirstMonth = isSameMonth(displayMonth, currentMonth);

  if (numberOfMonths === 1)
    return (
      <tfoot className="flex items-center justify-between py-2">
        <Button
          variant="tertiary"
          className="text-gray-800"
          onClick={() => setDate({ from: undefined, to: undefined })}
        >
          Reset
        </Button>
        <div className="flex w-full justify-end space-x-3">
          <Button
            variant="secondary"
            onClick={() =>
              setDate({
                from: undefined,
                to: undefined,
              })
            }
          >
            Cancel
          </Button>
          <Button disabled={!date?.from && !date?.to} onClick={onConfirm}>
            Apply
          </Button>
        </div>
      </tfoot>
    );
  return (
    <tfoot className="flex items-center justify-between py-2">
      {isFirstMonth && (
        <Button
          variant="tertiary"
          className="text-gray-800"
          onClick={() => setDate({ from: undefined, to: undefined })}
        >
          Reset
        </Button>
      )}
      {!isFirstMonth && (
        <div className="flex w-full justify-end space-x-3">
          <Button
            variant="secondary"
            onClick={() =>
              setDate({
                from: undefined,
                to: undefined,
              })
            }
          >
            Cancel
          </Button>
          <Button disabled={!date?.from && !date?.to} onClick={onConfirm}>
            Apply
          </Button>
        </div>
      )}
    </tfoot>
  );
}
