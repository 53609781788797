import { NextApiRequest, NextApiResponse } from 'next';
import { testSign } from '@utils/x-signature';
import tevoRoutes from '@config/tevoRoutes';
import {
  OrderTicketTaxQuotaRequest,
  PaymentType,
} from '@interfaces/ticketevolution/tikets';
import { apiFetcher } from '@instance';
import routes from '@config/routes';
import devConsole from '@utils/developer-console';

export default async function teTax(req: NextApiRequest, res: NextApiResponse) {
  // POST guard
  if (req.method !== 'POST' || !req.body) {
    res.writeHead(401);
    res.end();
    return;
  }

  // It was decided to use evopay as payment method
  const paymentsMethod: PaymentType = 'evopay';
  try {
    const taxQuotaRequest = {
      ticket_group_id: req.body.ticketGroupId,
      quantity: req.body.quantity,
      payment_type: paymentsMethod,
    };

    const ticketsTaxQuota = await testSign.postJSON<any>(
      tevoRoutes.taxQuotes,
      taxQuotaRequest
    );

    res.send(ticketsTaxQuota);
  } catch (e) {
    devConsole(e);
  }
}

export const getTicketsTaxQuotes = async (
  data: OrderTicketTaxQuotaRequest
): Promise<any> =>
  apiFetcher(routes.teTax, { body: data, credentials: 'include' });
