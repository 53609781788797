'use client';
import { EventMap } from '@assets/icons';
import { PACKAGE_STORAGE } from '@config/storageKeys';
import { useCurrencyContext } from '@contexts/currency-context';
import useStorage from '@hooks/useStorage';
import { IFormValues } from '@interfaces/buildPackage';
import { getHotelGuestsCount } from '@utils/filterForHotel';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@v2/utils';

import { useBuildPackagesStore } from 'src/stores/build-package-store';
import { simpleRenderToString } from '@v2/utils/simple-render-to-string';
import { MarkerPin03, Typography } from '@v2/ui';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { useGetHotels } from '../hooks/use-hotels-list';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { MapArrow } from '@v2/views/ViewHotel/components';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';

const apiKey = process.env.MAPS_API_KEY;
const region = 'us-east-1';
const mapName = 'explore.map';

interface MapEventProps {
  className?: string;
}

const styleMarker = {
  transform: 'translate(-50%, -50%)',
};

const mapStyle = {
  background: 'var(--ui-background-booking)',
};

const HotelMarker = ({ children }) => (
  <div
    role="button"
    tabIndex={0}
    style={styleMarker}
    className="relative inline-flex items-center justify-center rounded-lg bg-white px-2 py-1 shadow-md"
  >
    {children}
  </div>
);

type PackageDataType = IFormValues | null;

export const MapView: FC<MapEventProps> = ({ className }) => {
  const address = useBuildPackagesStore((state) => state.location);
  const storage = useStorage();
  const { priceRate, currency } = useCurrencyContext();
  const [position, setPosition] = useState({ lat: 0, lng: 0 });
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<any>(null);
  const [zoom] = useState(11);
  const { data: hotels } = useGetHotels();
  const { minTicketPrice } = useBuildPackageBundle();

  const getDataFromStorage = (name) => {
    const data = storage.getItem(name);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const [packageData] = useState<PackageDataType | null>(
    getDataFromStorage(PACKAGE_STORAGE)
  );

  const setHotelSelectedInMap = useSearchHotelStore(
    (state) => state.setHotelSelectedInMap
  );

  const [packageGuests, setPackageGuests] = useState<number>(0);

  const isBundle = useMemo<boolean>(() => {
    return packageData?.selected?.hotel && packageData?.selected?.tickets;
  }, [packageData]);

  useEffect(() => {
    const hotelGuests = getHotelGuestsCount(
      packageData?.roomConfigurations ?? []
    );
    const totalTickets = packageData?.tickets ?? 0;
    setPackageGuests(hotelGuests >= totalTickets ? hotelGuests : totalTickets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageData]);

  const openHotelDetails = (id) => {
    setHotelSelectedInMap(id, true);
  };

  const createHotelMarker = async (id, name, price) => {
    const finalPrice = !isBundle ? price : minTicketPrice + price;
    const hotelMarker = document.createElement('div');
    hotelMarker.addEventListener('click', () => openHotelDetails(id));

    const hotelMarkerContent = (
      <HotelMarker>
        <Typography size="txs" className="font-semibold">
          {formatCurrencyV2(
            finalPrice / packageGuests,
            priceRate,
            currency,
            false,
            false
          )}
        </Typography>

        <MapArrow />
        <div className="absol`ute -bottom-[20px] left-0 right-0 shadow-md" />
      </HotelMarker>
    );

    hotelMarker.innerHTML = await simpleRenderToString(hotelMarkerContent);

    return hotelMarker;
  };

  const drawMap = async () => {
    if (map.current) map.current.remove();
    map.current = new maplibregl.Map({
      container: mapContainer.current ?? '',
      style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
      center: [position.lng, position.lat],
      zoom,
    });

    const eventMarker = document.createElement('div');
    eventMarker.innerHTML = await simpleRenderToString(<MarkerPin03 />);

    new maplibregl.Marker({ element: eventMarker })
      .setLngLat([position.lng, position.lat])
      .addTo(map.current);
    hotels?.forEach(async ({ id, name, price, latitude, longitude }) => {
      const elm = await createHotelMarker(id, name, price);
      new maplibregl.Marker({ element: elm })
        .setLngLat([Number(longitude), Number(latitude)])
        .addTo(map.current);
    });
  };

  useEffect(() => {
    if (address) {
      setPosition({
        lat: Number(address.latitude),
        lng: Number(address.longitude),
      });
    }
  }, [address]);

  useEffect(() => {
    drawMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, hotels, currency]);

  return (
    <div
      ref={mapContainer}
      style={mapStyle}
      className={cn('h-[120px] w-full', className)}
    />
  );
};
