import { Wifi, Parking, Coffee, Pool, Fitness } from '@assets/icons';

import { CheckboxGroup } from '@components/FeatureFilter';

const mockData: CheckboxGroup[] = [
  {
    label: 'WiFi',
    value: 'WiFi',
    icon: <Wifi />,
  },
  {
    label: 'Parking',
    value: 'Parking',
    icon: <Parking />,
  },
  {
    label: 'Breakfast',
    value: 'Breakfast',
    icon: <Coffee />,
  },
  {
    label: 'Pool',
    value: 'Pool',
    icon: <Pool />,
  },
  {
    label: 'Fitness',
    value: 'Fitness',
    icon: <Fitness />,
  },
];

export const roomFilters: CheckboxGroup[] = [
  {
    label: 'Smoking',
    value: 'Smoking',
  },
  {
    label: 'Non-Smoking',
    value: 'Non-Smoking',
  },
  {
    label: 'Queen Bed',
    value: 'queenbed',
  },
  {
    label: 'King Bed',
    value: 'kingbed',
  },
  {
    label: 'Double Beds',
    value: 'doublebeds',
  },
];

export default mockData;
