'use client';

type Props = {
  event: LeagueEvent;
};

import { Button, SearchMD } from '@v2/ui';

import { RoomConfiguration } from '@components/RoomConfigurator';
import { ILocationResult } from '@components/searchCity';
import { pickValues } from '@config/buildPackage';
import { PACKAGE_STORAGE } from '@config/storageKeys';
import useStorage from '@hooks/useStorage';
import { LeagueEvent } from '@interfaces/APITravel';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { formatISO } from 'date-fns';
import { useFormik } from 'formik';
import { pick } from 'lodash';
import { useBuildPackagesStore } from 'src/stores/build-package-store';
import { useMediaQuery } from 'usehooks-ts';
import { invalidatePropertySearch } from '../../util';
import { BaseCheckInOutFilter } from './BaseFilters/BaseCheckinOutFilter';
import { BaseLocationFilter } from './BaseFilters/BaseLocationFilter';
import { BaseRoomsFilter } from './BaseFilters/BaseRoomsFilter';
import { MobileFilter } from './MobileFilter';

export type HeaderFormikProps = {
  location?: ILocationResult | undefined | null;
  hotelStartDate: string;
  hotelEndDate: string;
  roomConfigurations: RoomConfiguration[];
};

export function Header({ event }: Props) {
  const storage = useStorage();

  const { location, hotelEndDate, hotelStartDate, roomConfigurations } =
    useBuildPackagesStore((state) => ({
      location: state.location,
      hotelStartDate: state.hotelStartDate,
      hotelEndDate: state.hotelEndDate,
      roomConfigurations: state.roomConfigurations,
    }));

  const formik = useFormik<HeaderFormikProps>({
    initialValues: {
      location: location,
      hotelStartDate: hotelStartDate,
      hotelEndDate: hotelEndDate,
      roomConfigurations: roomConfigurations,
    },
    enableReinitialize: true,
    onSubmit: (val) => {
      const restData = useBuildPackagesStore.getState();

      const hotelStartDate = formik.values.hotelStartDate
        ? new Date(formik.values.hotelStartDate).toISOString().substring(0, 10)
        : '';

      const hotelEndDate = formik.values.hotelEndDate
        ? new Date(formik.values.hotelEndDate).toISOString().substring(0, 10)
        : '';

      storage.setItem(
        PACKAGE_STORAGE,
        JSON.stringify(
          pick(
            {
              ...restData,
              ...formik.values,
              hotelStartDate: formatISO(new Date(formik.values.hotelStartDate)),
              hotelEndDate: formatISO(new Date(formik.values.hotelEndDate)),
            },
            pickValues
          )
        )
      );

      useBuildPackagesStore.setState({ ...val });
      useSearchHotelStore.setState({ roomSelections: [] });

      invalidatePropertySearch();
    },
  });

  const matches = useMediaQuery('(min-width: 1024px)');

  if (!matches) return <MobileFilter event={event} formik={formik} />;

  return (
    <>
      <form
        className="mb-12 flex items-center gap-x-3"
        onSubmit={formik.handleSubmit}
      >
        <BaseLocationFilter formik={formik} />
        <BaseCheckInOutFilter formik={formik} event={event} />
        <BaseRoomsFilter formik={formik} />

        <Button className="h-[72px] shrink-0 px-6" type="submit">
          <SearchMD className="text-white" />
        </Button>
      </form>
    </>
  );
}
