'use client';
import { ILocationResult } from '@components/searchCity';
import { buildPackageInitValuesV2, pickValues } from '@config/buildPackage';
import { NEXT_STEPS, TrackedEvents } from '@config/index';
import routes from '@config/routes';
import STATES from '@config/states.json';
import {
  EVENT_STORAGE,
  PACKAGE_STORAGE,
  UUID_STORAGE,
} from '@config/storageKeys';
import useEventLoader from '@hooks/use-event-loader';
import { useScrollToTopOnMount } from '@hooks/use-scroll-top-on-mount';
import useNext from '@hooks/useNext';
import useStorage from '@hooks/useStorage';
import instance from '@instance';
import { IVenue, LeagueEvent, Venue } from '@interfaces/APITravel';
import { LoggedInUserProps } from '@interfaces/auth';
import invalidatePackageData from '@utils/cache-invalidate';
import devConsole from '@utils/developer-console';
import getCookie from '@utils/get-cookie';
import { getLatLng, searchLocation } from '@utils/location';
import { v2Links } from '@utils/navigation/links';
import { PageLoader } from '@v2/components';
import { Typography } from '@v2/ui';
import { differenceInCalendarDays } from 'date-fns';
import { useFormik } from 'formik';
import { pick } from 'lodash';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useBuildPackagesStore } from 'src/stores/build-package-store';
import { useMediaQuery } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';
import { PackageType } from './BuyPackage';
import { BuyTickets } from './components';
import { BuyHotel } from './components/BuyHotel';
import { isHotelValid } from './components/util';

const initialSteps = {
  tickets: undefined,
  fly: undefined,
  hotel: undefined,
};

export type StepName = keyof typeof initialSteps;
interface BuildPackageProps {
  event?: LeagueEvent;
  eventId?: string;
  user: LoggedInUserProps
}

const exceptionVenues = [43457];

export function BuildYourPackage({ event, eventId, user }: BuildPackageProps) {
  useScrollToTopOnMount();

  const [steps, setSteps] = useState(initialSteps);
  const { hotel, tickets, fly } = steps;
  const storage = useStorage();
  const next = useNext(NEXT_STEPS.checkout);
  const searchParams = useSearchParams();
  const packageType = searchParams!.get('packageType')! as PackageType;
  const [isHotel, setIsHotel] = useState(false);
  const [hotelLocation, setHotelLocation] = useState<
    ILocationResult | null | undefined
  >(undefined);
  const md = useMediaQuery('(min-width: 768px)');

  const [hotelLoading, setHotelLoading] = useState<boolean>(false);

  const router = useRouter();
  const { tickets: ticketsList, loading: ticketsLoading } = useEventLoader(
    `${(event as LeagueEvent).id}`,
    true
  );

  const formik = useFormik({
    initialValues: {
      ...buildPackageInitValuesV2(event, hotelLocation, packageType),
    },
    enableReinitialize: true,
    onSubmit: (val) => {
      const anonymousId =
        getCookie('anonymous-user-id')?.toString() ||
        'anonymous-user-id not found';

      if (values.tickets > 0) {
        instance.server(routes.track, {
          body: JSON.stringify({
            userId: user?.id ? Number(user.id) : undefined,
            anonymousId: user ? undefined : anonymousId,
            trackedEvent: TrackedEvents.BYP_TICKET_SELECTED,
            properties: JSON.stringify({
              ticketCount: val.tickets
            }),
          }),
          method: 'POST',
        });
      }


      if (packageType === 'packages') {

        const numRooms = values.roomConfigurations.length;
        const numAdults = values.roomConfigurations.reduce(
          (acc, cur) => acc + cur.adults,
          0
        );
        const totalChildren = values.roomConfigurations.reduce(
          (acc, cur) => acc + cur.children.length,
          0
        );

        const numInfants = values.roomConfigurations.reduce(
          (acc, cur) => acc + cur.infants,
          0
        );
        const numChildren = totalChildren + numInfants;
        const nights = differenceInCalendarDays(
          new Date(values.hotelEndDate),
          new Date(values.hotelStartDate)
        ) ?? 1


        instance.server(routes.track, {
          body: JSON.stringify({
            userId: user?.id ? Number(user.id) : undefined,
            anonymousId: user ? undefined : anonymousId,
            trackedEvent: TrackedEvents.BYP_ROOM_SELECTED,
            properties: JSON.stringify({
              nights,
              infants: numInfants,
              children: numChildren,
              adults: numAdults,
              rooms: numRooms,
            }),
          }),
          method: 'POST',
        });

      }

      useBuildPackagesStore.setState({
        ...val,
        selected: { ...steps },
      });

      if (
        packageType === 'tickets' ||
        (packageType === 'packages' && !steps.hotel)
      )
        return router.push(`${v2Links.searchTickets}?eventId=${eventId}`);

      router.push(`${v2Links.searchHotel}?eventId=${eventId}`);
    },
  });

  useEffect(() => {
    const prevEventId = storage.getItem(EVENT_STORAGE);
    if (prevEventId && prevEventId !== eventId) {
      invalidatePackageData();
    }
    if (eventId) storage.setItem(EVENT_STORAGE, eventId.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const { values } = formik;

  const hotelStartDate = formik.values.hotelStartDate
    ? new Date(formik.values.hotelStartDate).toISOString().substring(0, 10)
    : '';

  const hotelEndDate = formik.values.hotelEndDate
    ? new Date(formik.values.hotelEndDate).toISOString().substring(0, 10)
    : '';

  useEffect(() => {
    storage.setItem(
      PACKAGE_STORAGE,
      JSON.stringify(
        pick(
          {
            ...formik.values,
            hotelStartDate,
            hotelEndDate,
            selected: {
              hotel,
              tickets,
            },
          },
          pickValues
        )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, steps]);

  useEffect(() => {
    if (!hotelLocation && packageType === 'packages') {
      const { venue } = event as LeagueEvent;
      getHotelLocation(venue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelLocation, packageType]);

  useEffect(() => {
    const configurationUuid = uuidv4().substring(0, 8);
    storage.setItem(UUID_STORAGE, configurationUuid);
  }, [storage]);
  const getHotelLocation = async (venue: Venue | IVenue) => {
    setHotelLoading(true);
    try {
      if (
        !STATES.US.some((state) =>
          venue?.address?.location.includes(state.value)
        ) &&
        !STATES.CA.some((state) =>
          venue?.address?.location?.includes(state.value)
        )
      ) {
        return;
      }

      const venueLocationValues: string[] = [];
      if (venue?.name) venueLocationValues.push(venue?.name);
      if (venue?.keywords) venueLocationValues.push(venue?.keywords);
      if (venue?.location) venueLocationValues.push(venue?.location);
      if (
        exceptionVenues.includes(venue.id) &&
        venue?.address?.street_address
      ) {
        venueLocationValues.push(venue?.address?.street_address);
      }
      if (venue?.address?.postal_code)
        venueLocationValues.push(venue?.address?.postal_code);
      const venueLocation = venueLocationValues.join(', ');
      const possibleLocations = await searchLocation(venueLocation);

      if (possibleLocations.length) {
        const { PlaceId: placeId, Text: description } = possibleLocations[0];

        const fullName = description.split(', ');
        if (!exceptionVenues.includes(venue.id)) {
          fullName.shift();
        }
        const usedDescription = fullName.join(', ');

        const locationCoords = await getLatLng(placeId);

        const locationResult = {
          id: placeId,
          name: `${venue?.name}, ${usedDescription}`,
          country_name: fullName[fullName.length - 1],
          city_name: venue?.address?.location,
          latitude: locationCoords.Geometry.Point[1],
          longitude: locationCoords.Geometry.Point[0],
        } as ILocationResult;

        setHotelLocation(locationResult);
      }
    } catch (e) {
      devConsole(e);
    } finally {
      setHotelLoading(false);
    }
  };

  const setStep = (name: StepName, value: boolean) => {
    setSteps((prevState) => ({ ...prevState, [name]: value }));
    const hotelStartDate = formik.values.hotelStartDate
      ? new Date(formik.values.hotelStartDate).toISOString().substring(0, 10)
      : '';

    const hotelEndDate = formik.values.hotelEndDate
      ? new Date(formik.values.hotelEndDate).toISOString().substring(0, 10)
      : '';
    storage.setItem(
      PACKAGE_STORAGE,
      JSON.stringify(
        pick(
          {
            ...formik.values,
            hotelStartDate,
            hotelEndDate,
            selected: {
              hotel,
              tickets,
              fly,
              [name]: value,
            },
          },
          pickValues
        )
      )
    );
    const newSteps = {
      hotel,
      tickets,
      fly,
      [name]: value,
    };
    if (newSteps.hotel !== undefined && newSteps.tickets !== undefined) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }
  };

  const onCancelHotel = () => {
    setStep('hotel', false);
  };

  const onActionHotel = () => {
    setStep('hotel', true);
  };

  const toggleHotel = (val: boolean) => {
    setIsHotel(val);
    setStep('hotel', val);

  }

  const onActionTickets = () => {
    setStep('tickets', true);
    if (packageType !== 'packages') return;
    if (hotelLocation) {
      toggleHotel(true);

    } else {
      onCancelHotel();
    }
  };

  useEffect(() => {
    if (steps.hotel && steps.tickets) {
      router.prefetch(`${v2Links.searchHotel}?eventId=${eventId}`);
    }

    if (steps.tickets)
      router.prefetch(`${v2Links.searchTickets}?eventId=${eventId}`);
  }, [router, steps]);
  const isHotelComplete = isHotelValid(formik);

  const getIsDisabled = () => {
    if (packageType === 'tickets' && !steps.tickets) return true;

    if (packageType === 'packages' && steps.hotel && !isHotelComplete)
      return true;

    if (packageType === 'packages' && !steps.tickets && !steps.hotel)
      return true;

    if (
      packageType === 'packages' &&
      !steps.tickets &&
      !steps.hotel &&
      !isHotelComplete
    )
      return true;

    return false;
  };

  if (ticketsLoading && hotelLoading) return <PageLoader />;

  return (
    <section className="buy-package-height px-4 pt-8 md:px-20 md:pt-12">
      <div className="mt-6 flex flex-col items-center">
        <div className="w-full md:w-[880px]">
          <div>
            <Typography
              size={md ? 'dmd' : 'dxs'}
              className="mb-3 text-center font-bold md:mb-4"
            >
              Build your package
            </Typography>
            <Typography
              size="tsm"
              className="text-center font-medium text-gray-500"
            >
              Choose whether you&apos;d like tickets and/or accommodations.
            </Typography>
            <form
              className="mt-8 w-full rounded-xl border border-gray-200 bg-white md:mt-12"
              onSubmit={formik.handleSubmit}
            >
              <BuyTickets
                onAction={onActionTickets}
                formik={formik}
                setStep={setStep}
                loading={ticketsLoading}
                ticketEntries={ticketsList?.total_entries}
                toggleHotel={toggleHotel}
              />
              <BuyHotel
                isEnabled={isHotel}
                open={steps.hotel}
                onAction={onActionHotel}
                onCancel={onCancelHotel}
                formik={formik}
                setStep={setStep}
                loading={hotelLoading}
                hotelLocation={hotelLocation}
                event={event}
                isBuildPackageComplete={getIsDisabled()}
              />

              <div className="flex w-full flex-col items-end justify-end gap-y-4 p-6 md:flex-row md:items-center md:justify-between md:p-8">
                {getIsDisabled() ? (
                  <span className="md:text-md text-sm font-semibold text-gray-800">
                    You need to select at least one item in order to continue.
                  </span>
                ) : (
                  <div />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
