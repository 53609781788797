import devConsole from '@utils/developer-console';
import { useEffect, useRef, useState } from 'react';

interface Percentiles {
  '0.2': string | '#FFC515';
  '0.4': string | '#f2711c';
  '0.6': string | '#D6226A';
  '0.8': string | '#a333c8';
  '1': string | '#2A6EBB';
}

export interface ITevomaps {
  venueId: number /* Ticket Map Venue ID */;
  configurationId: number /* Ticket Map Configuration ID */;
  sectionPercentiles?: Percentiles;
  /* Define percentiles to color sections based on their average ticket group price.
  Ticket groups which fall within a given range will display the associated color on the map for their section. */

  /* ie. Given an event with 100 ticket groups, with each ticket group price incrementing by $1 from $100 to $200,
  a section whose average ticket group price is $110 will be displayed as #FFC515, and a section whose average
  price of $175 will be displayed as #a333c8. */
  mapFontFamily?: string;
  mapsDomain?: string;
  onSelection?(sections: string[]): void;
  selectedSections?: string[];
  ticketGroups?: TicketGroup[];
  showControls?: boolean;
  showLegend?: boolean;
  mouseControlEnabled?: boolean;
}

export interface TicketGroup {
  tevo_section_name: string;
  retail_price: number;
}

interface MapBuild {
  build(options): PublicApi;
}

export interface PublicApi {
  updateTicketGroups: (ticketGroups: TicketGroup[]) => void;
  highlightSection: (section: string) => void;
  unhighlightSection: (section?: string) => void;
  selectSection: (section: string) => void;
  deselectSection: (section?: string) => void;
}

interface EmptyTevomapsClass {
  new (value?: ITevomaps): MapBuild;
}

export const loadMaps = async () =>
  import('@ticketevolution/seatmaps-client/build/tevomaps');

const useTevomaps = (): { Tevomaps: EmptyTevomapsClass; isLoaded: boolean } => {
  const TevomapsRef = useRef<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { default: tevomaps } = await loadMaps();
        TevomapsRef.current = tevomaps;
        setIsLoaded(true);
      } catch (e) {
        devConsole(e);
      }
    })();
  }, []);

  return { Tevomaps: TevomapsRef.current, isLoaded };
};

export default useTevomaps;
