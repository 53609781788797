'use client';
import { listSorterOption } from '@config/index';
import routes from '@config/routes';
import type { BaseProperty } from '@interfaces/expedia/property';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { useMediaQuery } from '@uidotdev/usehooks';
import { Button, ChevronDown, Typography } from '@v2/ui';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import pluralize from 'pluralize';
import { useState } from 'react';
import { mutate } from 'swr';
import { useGetHotels } from '../../hooks/use-hotels-list';
import { SortByDropdown } from '../Filters';
import * as HotelCard from './HotelCard';
import { HotelChipFilters } from './HotelChipFilters';
import { HotelsListPlaceholder } from './HotelsListPlacehoder';
import { invalidatePropertySearch } from '../../util';
import useEventLoader from '@hooks/use-event-loader';
import useMinimumPrice from '@hooks/useMinimumPrice';
import { useBuildPackagesStore } from '@store/build-package-store';

const PER_PAGE = 10;

export function HotelsContent() {
  const sortBy = useSearchHotelStore((state) => state.hotelFilters.sortBy);

  const sortByLabel = useSearchHotelStore(
    (state) =>
      listSorterOption.find((val) => val.value === state.hotelFilters.sortBy)
        ?.name ?? 'Distance'
  );
  const setFilter = useSearchHotelStore((state) => state.setHotelFilters);

  const { data, isValidating } = useGetHotels();

  const handleSort = (value: string) => {
    setFilter({ sortBy: value });

    invalidatePropertySearch();
  };
  const matches = useMediaQuery('(min-width: 1024px)');

  return (
    <>
      {matches && (
        <>
          <div className="mb-4 flex items-center justify-between">
            <Typography size="tmd" className="font-medium text-gray-500">
              {data.length} {pluralize('hotel', data.length)} found
            </Typography>

            <SortByDropdown value={sortBy} onSelect={(val) => handleSort(val)}>
              <Typography
                size="tmd"
                className="inline-flex items-center gap-x-1.5 font-semibold"
              >
                Sort by {sortByLabel}{' '}
                <ChevronDown pathProps={{ stroke: 'currentColor' }} />
              </Typography>
            </SortByDropdown>
          </div>
          <HotelChipFilters />
        </>
      )}

      {isValidating ? <HotelsListPlaceholder /> : <List list={data} />}
    </>
  );
}

function List({ list }: { list: BaseProperty[] }) {
  const [displayCount, setDisplayCount] = useState(PER_PAGE);
  const searchParams = useSearchParams();

  const eventId = searchParams!.get('eventId')!;
  const { tickets, loading } = useEventLoader(eventId, true);

  const handleShowMore = () => {
    setDisplayCount(displayCount + PER_PAGE);
  };

  if (!list.length)
    return (
      <div className="flex min-h-[262px] w-full flex-col items-center justify-center rounded-xl border border-gray-200 bg-white">
        <Image
          src="/icons/search-hotel.svg"
          width={80}
          height={80}
          alt="search  hotel "
        />
        <Typography size="txl" className="mb-2 mt-6 font-bold text-gray-500">
          No hotels found
        </Typography>
        <Typography size="tmd" className="font-medium text-gray-500">
          Review your filters or try a different search
        </Typography>
      </div>
    );

  return (
    <>
      <div className="flex flex-col gap-y-4">
        {list.slice(0, displayCount).map((hotel, i) => (
          <HotelItem {...hotel} key={i} />
        ))}
      </div>
      {list.length > 10 && (
        <Button
          variant="ghost"
          className="mt-4 w-full bg-white"
          onClick={handleShowMore}
        >
          Show more
        </Button>
      )}
    </>
  );
}

export function HotelItem(
  props: BaseProperty & {
    className?: string;
    onItemClick?: (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      val: BaseProperty
    ) => void;
  }
) {
  const { image, onItemClick } = props;
  const searchParams = useSearchParams();
  const eventId = searchParams!.get('eventId')!;

  const handleClick = (
    elm: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    onItemClick && onItemClick(elm, props);
  };

  return (
    <Link
      href={`/search-hotel/${props.id}?eventId=${eventId}`}
      onClick={handleClick}
      className="block w-full"
    >
      <HotelCard.Card>
        <HotelCard.Content>
          <HotelCard.HotelImage image={image} />
          <HotelCard.Overview {...props} />
        </HotelCard.Content>
      </HotelCard.Card>
    </Link>
  );
}
