import { Skeleton } from '@v2/ui';

export function ContentSkeleton() {
  return (
    <>
      <Skeleton className="mb-12 min-h-[915px] w-full" />

      <div className="flex flex-wrap gap-3">
        {[0, 1, 2, 3, 4, 5].map((item) => (
          <Skeleton
            key={item.toString()}
            className="min-h-[603px] w-full max-w-[360px] md:max-w-[400px]"
          />
        ))}
      </div>
    </>
  );
}
