import {
  DEFAULT_DISTANCE,
  useSearchHotelStore,
} from '@store/search-hotel-store';
import { Badge, Close } from '@v2/ui';
import pluralize from 'pluralize';
import { useGetHotels } from '../../hooks/use-hotels-list';
import { invalidatePropertySearch } from '../../util';

export function HotelChipFilters() {
  const hotelFilters = useSearchHotelStore((state) => state.hotelFilters);
  const { distance, featureHotel, pricePerTraveler, query, rating } =
    hotelFilters;

  const hasDistanceChanged = useSearchHotelStore(
    (state) => state.hasDistanceChanged
  );
  const hasPricePerTravelerChanged = useSearchHotelStore(
    (state) => state.hasPricePerTravelerChanged
  );

  const setHotelFilter = useSearchHotelStore((state) => state.setHotelFilters);

  const { maxHotelPrice } = useGetHotels();

  return (
    <div className="mb-6 flex flex-wrap items-center gap-x-4">
      {query && (
        <Badge size="lg" variant="secondary" className="max-w-[126px] truncate">
          {query}
          <button onClick={() => setHotelFilter({ query: '' })}>
            <Close size="16" className="ml-1 text-gray-500" />
          </button>
        </Badge>
      )}
      {featureHotel.length > 0 && (
        <Badge size="lg" variant="secondary" className="max-w-[126px] truncate">
          {featureHotel?.length} {pluralize('feature', featureHotel?.length)}
          <button onClick={() => setHotelFilter({ featureHotel: [] })}>
            <Close size="16" className="ml-1 text-gray-500" />
          </button>
        </Badge>
      )}

      {rating.length > 0 &&
        rating.map((val) => (
          <Badge
            key={val}
            size="lg"
            variant="secondary"
            className="max-w-[126px] truncate"
          >
            {val} {pluralize('star', Number(val))}
            <button
              onClick={() =>
                setHotelFilter({
                  rating: [...rating].filter((rating) => rating !== val),
                })
              }
            >
              <Close size="16" className="ml-1 text-gray-500" />
            </button>
          </Badge>
        ))}

      {hasDistanceChanged && (
        <Badge size="lg" variant="secondary">
          {distance} mi
          <button
            onClick={() => {
              setHotelFilter({ distance: DEFAULT_DISTANCE });
              useSearchHotelStore.setState({ hasDistanceChanged: false });
              invalidatePropertySearch();
            }}
          >
            <Close size="16" className="ml-1 text-gray-500" />
          </button>
        </Badge>
      )}

      {hasPricePerTravelerChanged && (
        <Badge size="lg" variant="secondary">
          ${pricePerTraveler[0]} - ${pricePerTraveler[1].toFixed(0)}
          <button
            onClick={() => {
              setHotelFilter({ pricePerTraveler: [0, maxHotelPrice] });
              useSearchHotelStore.setState({
                hasPricePerTravelerChanged: false,
              });
            }}
          >
            <Close size="16" className="ml-1 text-gray-500" />
          </button>
        </Badge>
      )}
    </div>
  );
}
