'use client';
import {
  CHECKOUT_START_DATETIME,
  PACKAGE_STORAGE,
  SELECTED_HOTEL_STORAGE,
  SELECTED_TICKET_STORAGE,
  SOCIAL_LOGIN_REDIRECT,
} from '@config/storageKeys';
import { useCurrencyContext } from '@contexts/currency-context';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';
import { TicketGroup } from '@hooks/use-tevomaps';
import useMinimumPrice from '@hooks/useMinimumPrice';
import useStorage from '@hooks/useStorage';
import { LeagueEvent } from '@interfaces/APITravel';
import { IFormValues } from '@interfaces/buildPackage';
import {
  ExtendedShippedItem,
  ShipmentFormat,
  ShipmentType,
} from '@interfaces/ticketevolution/tikets';
import { useTicketsStore } from '@store/tickets-store';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { links } from '@utils/navigation/links';
import { Badge, Button, Check } from '@v2/ui';
import { cn } from '@v2/utils';
import { setCookie } from 'cookies-next';
import moment from 'moment-timezone';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useMemo, useState } from 'react';

type Props = {
  event: LeagueEvent;
  ticketGroups: TicketGroup[];
};

export function TicketSelectionBottomHeader(props: Props) {
  const { event, ticketGroups } = props;
  const storage = useStorage();

  const [currentPackageData] = useState<IFormValues>(
    JSON.parse(storage.getItem(PACKAGE_STORAGE) ?? '{}')
  );
  const ticketsQuantity = useTicketsStore((state) => state.ticketsQuantity);

  const { ticketPrice: minTicketPrice } = useMinimumPrice(
    [],
    ticketGroups,
    ticketsQuantity
  );

  const searchParams = useSearchParams();
  const eventId = searchParams?.get('eventId');

  const router = useRouter();

  const ticketSelected = useTicketsStore((state) => state.ticketSelected);

  const selectedHotel = JSON.parse(
    storage.getItem(SELECTED_HOTEL_STORAGE) || '{}'
  );

  const { isBundle, packageGuests } = useBuildPackageBundle();

  const { priceRate, currency } = useCurrencyContext();

  const taxQuota = useTicketsStore((state) => state.taxQuota);
  const isTicketSelectionConfirmed = useTicketsStore(
    (state) => state.isTicketSelectionConfirmed
  );

  const getShipmentType = useCallback((type: ShipmentFormat): ShipmentType => {
    const {
      venue: { time_zone },
      occurs_at,
    } = event;

    const currentDate = moment.tz(
      new Date().toLocaleString('en-US', {
        timeZone: time_zone || 'America/New_York',
      }),
      time_zone
    );

    const eventDate = moment.tz(occurs_at, time_zone);

    const hoursUntilEvent = eventDate.diff(currentDate, 'hours', true);

    switch (type) {
      case 'Flash_seats':
        return 'FlashSeats';
      case 'TM_mobile':
        return 'TMMobile';
      case 'Physical':
        return hoursUntilEvent > 18 ? 'FedEx' : 'LocalPickup';
      default:
        return type;
    }
  }, []);

  const onTicketClick = async () => {
    if (!ticketSelected) return;

    const {
      signature,
      id: ticketGroupId,
      retail_price: retailPrice,
      wholesale_price: wholesalePrice,
      section,
      row,
      format: type,
      public_notes: publicNotes,
    } = ticketSelected;

    const result: ExtendedShippedItem = {
      price: wholesalePrice,
      wholesalePrice,
      retailPrice,
      tax: taxQuota.tax,
      tax_signature: taxQuota?.tax_signature || '',
      ticket_group_id: ticketGroupId,
      quantity: ticketsQuantity,
      seat_order: 'consecutive',
      ticket_group_signature: signature,
      stadiumName: event.venue.name || '',
      section,
      row,
      publicNotes: publicNotes || '',
    };

    storage.setItem(
      SELECTED_TICKET_STORAGE,
      JSON.stringify({
        ...result,
        type: getShipmentType(type as ShipmentFormat),
      })
    );

    const checkoutUrl = `${links.checkout}?eventId=${eventId}`;
    storage.setItem(SOCIAL_LOGIN_REDIRECT, checkoutUrl);
    setCookie(CHECKOUT_START_DATETIME, new Date().getTime());
    router.push(checkoutUrl);
  };

  // TODO REFACTOR LATER
  const totalPrice = useMemo(() => {
    // IF TICKETS ONLY

    if (isBundle && !ticketSelected) {
      const { price: hotelPriceSelection } = selectedHotel;

      return hotelPriceSelection + minTicketPrice;
    }

    if (!ticketSelected && !isBundle) {
      return 0;
    }

    const retailPrice = ticketSelected?.retail_price;

    if (!isBundle) return retailPrice! * ticketsQuantity + taxQuota.tax;

    const { price: hotelPriceSelection } = selectedHotel;

    return hotelPriceSelection + retailPrice! * ticketsQuantity + taxQuota.tax;
  }, [
    selectedHotel,
    isBundle,
    priceRate,
    currency,
    ticketSelected,
    taxQuota?.tax,
  ]);

  const isHotelIncluded = currentPackageData?.selected?.hotel;

  const bottomTotalPriceLabel = isHotelIncluded
    ? 'Include taxes, hotel & tickets'
    : 'Include taxes & tickets';

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 w-full bg-gray-900">
      <div className="flex flex-col items-center justify-between gap-y-4 px-4 py-5 md:mx-auto md:max-w-[1400px] md:flex-row md:px-20">
        <div className="flex items-start justify-between gap-x-3 md:justify-start">
          <div>
            <div className="flex items-start gap-x-3 md:items-center">
              <h5 className="text-lg font-bold text-white">
                Estimated{' '}
                {formatCurrencyV2(
                  totalPrice / packageGuests,
                  priceRate,
                  currency,
                  true,
                  false
                )}{' '}
                per person
              </h5>
              {isTicketSelectionConfirmed && (
                <Badge
                  size="sm"
                  variant="primary"
                  className="flex-shrink-0 rounded-full"
                >
                  <>
                    <Check size="12" pathProps={{ stroke: 'white' }} />

                    <span className="ml-2.5 text-xs font-semibold text-white">
                      Tickets selected{' '}
                    </span>
                  </>
                </Badge>
              )}
            </div>
            <span className="text-sm font-medium text-gray-400">
              {formatCurrencyV2(totalPrice, priceRate, currency, true, false)}{' '}
              in total. {bottomTotalPriceLabel}
            </span>
          </div>
        </div>

        <Button
          onClick={onTicketClick}
          disabled={!isTicketSelectionConfirmed}
          className={cn(
            !isTicketSelectionConfirmed && 'bg-gray-800 text-gray-400',
            'w-full md:w-max'
          )}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
