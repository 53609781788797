import { Skeleton } from '@v2/ui';

export function HotelsListPlaceholder() {
  return (
    <div className="space-y-4">
      {[0, 1, 2, 3, 4, 5].map((item) => (
        <div
          className="w-full rounded-lg border border-gray-200 bg-white p-4 md:p-6"
          key={item.toString()}
        >
          <div className="flex items-stretch gap-x-5">
            <Skeleton className="h-[178px] min-w-[72px] shrink-0 rounded-tl-lg md:h-[246px] md:min-w-[246px]" />
            <div className="flex flex-col items-start justify-start">
              <Skeleton className="mb-[15px] h-5 w-[150px] md:w-[260px]" />
              <Skeleton className="md:-[80px] h-5 w-[120px]" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
