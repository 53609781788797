import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Loader,
  Loader2,
} from '@v2/ui';
import React, { PropsWithChildren } from 'react';

import { cn } from '@v2/utils';
import {
  CollapsibleProps,
  CollapsibleTriggerProps,
} from '@radix-ui/react-collapsible';

function Card({ children, ...props }: PropsWithChildren & CollapsibleProps) {
  return (
    <Collapsible className="flex w-full flex-col gap-y-8 p-6 md:p-8" {...props}>
      {children}
    </Collapsible>
  );
}
Card.displayName = Card;

function Header({
  children,
  trigger,
  ...props
}: PropsWithChildren &
  CollapsibleTriggerProps & { trigger?: React.ReactNode; loading: boolean }) {
  const { disabled, loading } = props;
  return (
    <CollapsibleTrigger
      disabled={disabled || loading}
      className={cn(
        'flex w-full items-center justify-between',
        disabled && 'cursor-not-allowed',
        loading && 'cursor-wait'
      )}
      {...props}
    >
      <div className="flex items-center gap-x-4">{children}</div>
      {loading ? (
        <Loader2
          style={{
            width: '28px',
            height: '28px',
            transform: 'scale(1)',
          }}
        />
      ) : (
        trigger
      )}
    </CollapsibleTrigger>
  );
}
Header.displayName = Header;

function ContentCollapse({
  children,
  className,
}: PropsWithChildren & {
  className?: string;
}) {
  return (
    <CollapsibleContent className={cn('space-y-8', className)}>
      {children}
    </CollapsibleContent>
  );
}

ContentCollapse.displayName = ContentCollapse;

Header.displayName = Header;

export { Card, Header, ContentCollapse };
