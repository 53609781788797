import { useEffect } from 'react';

export const useScrollToTopOnMount = () => {
  useEffect(() => {
    // Check if the window object is available
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []); // The empty dependency array ensures this runs only on mount
};
