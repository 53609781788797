import { RoomConfiguration } from '@components/RoomConfigurator';
import { useMemo } from 'react';

type UseCheckInOutProps = {
  roomConfigurations: RoomConfiguration[];
};

export function useCheckInOut(props: UseCheckInOutProps) {
  const { roomConfigurations } = props;

  const rawValues = useMemo(() => {
    const numRooms = roomConfigurations.length;
    const numAdults = roomConfigurations.reduce(
      (acc, cur) => acc + cur.adults,
      0
    );
    const children = roomConfigurations.reduce(
      (acc, cur) => acc + cur.children.length,
      0
    );

    const infants = roomConfigurations.reduce(
      (acc, cur) => acc + cur.infants,
      0
    );
    return {
      numRooms,
      numAdults,
      children,
      infants,
    };
  }, [roomConfigurations]);
  const { children, numRooms, numAdults, infants } = rawValues;

  const totalChildren = children + infants;
  const formattedValue = (
    <>
      {numRooms} Room, {numAdults} Adult{numAdults > 1 ? 's' : ''}
      {totalChildren > 0 && (
        <>
          ,&nbsp;{totalChildren} Child{totalChildren > 1 ? 'ren' : ''}
        </>
      )}
    </>
  );

  const models = {
    rawValues,
    formattedValue,
  };
  const operations = {};
  return [models, operations] as [typeof models, typeof operations];
}
