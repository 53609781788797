import { RoomConfiguration } from '@components/RoomConfigurator';
import type { Room } from '@interfaces/expedia/property';
import { RoomWithRate } from './RoomsList';

// export function sortRoomsByOrder(rooms: Room[], selectedSortingOption: string) {
//   const order = selectedSortingOption === 'price-low-high' ? 1 : -1;

//   const innerSortedRooms =
//     rooms?.map((room) => {
//       const sortedRooms = room.rates.sort((r1, r2) => {
//         const occupancy1 = r1.occupancyPricing.sort(
//           (a, b) =>
//             order * (parseFloat(a.total.value) - parseFloat(b.total.value))
//         );
//         const occupancy2 = r2.occupancyPricing.sort(
//           (a, b) =>
//             order * (parseFloat(a.total.value) - parseFloat(b.total.value))
//         );
//         return (
//           order *
//           (parseFloat(occupancy1[0].total.value) -
//             parseFloat(occupancy2[0].total.value))
//         );
//       });

//       return {
//         ...room,
//         rooms: sortedRooms,
//       };
//     }) ?? null;

//   const sortedRoomList =
//     innerSortedRooms?.sort((a, b) => {
//       return (
//         order *
//         (parseFloat(a.rooms[0]?.occupancyPricing[0]?.total?.value) -
//           parseFloat(b.rooms[0]?.occupancyPricing[0]?.total?.value))
//       );
//     }) ?? null;
//   return sortedRoomList;
// }
export function sortRoomsByOrder(rooms: Room[], selectedSortingOption: string) {
  const order = selectedSortingOption === 'price-low-high' ? 1 : -1;

  // Flatten the room and rate structure into an array of { room, rate } pairs
  const rateList = rooms.reduce((acc: RoomWithRate[], room) => {
    const ratesWithRoom = room.rates.map((rate) => ({
      room,
      rate,
      price: parseFloat(rate.occupancyPricing[0].total.value), // Assumes the first occupancy pricing is the relevant one
    }));
    return acc.concat(ratesWithRoom);
  }, []);

  // Sort the rate list by price
  rateList.sort((a, b) => order * (a.price - b.price));

  return rateList;
}

export function sortRoomsByRoomType(rooms: Room[], sortingOption: string) {
  if (!sortingOption) return rooms;
  // Split the sortingOption string into an array of filter options
  const filterOptions = sortingOption.split(',');

  const filteredListRoom =
    rooms?.filter((r) =>
      filterOptions.some((roomFeature) => {
        if (roomFeature.includes('Smoking')) {
          return r.amenities.includes(roomFeature);
        }

        return (
          r.rates?.some((rate) => {
            return rate.bedgroups?.some((bedgroup) => {
              return bedgroup.description
                .toLowerCase()
                .replaceAll(' ', '')
                .replaceAll('-', '')
                .includes(roomFeature.trim()); // Trim roomFeature to remove whitespace
            });
          }) ||
          r.name
            .toLowerCase()
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .includes(roomFeature.trim()) // Trim roomFeature to remove whitespace
        );
      })
    ) ?? null;

  return filteredListRoom;
}

export function buildOccupancies(roomConfigurations: RoomConfiguration[]) {
  const occupancies: string[] = [];

  roomConfigurations.forEach((roomConfiguration) => {
    let occupancy = `${roomConfiguration.adults}`;

    if (roomConfiguration.children.length) {
      occupancy += '-';

      roomConfiguration.children.forEach((age) => {
        occupancy += age;
        occupancy += ',';
      });

      occupancy = occupancy.substr(0, occupancy.length - 1);
    }
    occupancies.push(occupancy);
  });

  return occupancies;
}
