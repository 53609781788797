import type { Rate, Room } from '@interfaces/expedia/property';
import {
  Bed,
  Button,
  Close,
  Coffee,
  CornerUpLeft,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ScrollArea,
  Typography,
  Users01,
  Wifi,
} from '@v2/ui';

import { usePrevNextButtons } from '@v2/components/Carousel/ArrowButtons';
import { ChevronLeft, ChevronRight } from '@v2/ui';
import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import { PropsWithChildren, useState } from 'react';
import { OccupancyRates } from './OccupancyRates';

type Props = {
  room: Room;
  rate: Rate;
  price: string;
  minimumPricePerOccupancy: Record<string, number>;
  handleSelectRoom: (price: number, ocupancy: string, modifier: number) => void;
  children: React.ReactNode;
};

export function RoomDetailsDialog({
  room,
  rate,
  price,
  handleSelectRoom,
  minimumPricePerOccupancy,
}: Props) {
  const { occupancyPricing, refundable } = rate;
  const { images } = room;
  const [isOpen, setIsOpen] = useState(false);

  const { description } = rate.bedgroups[0];
  const onCloseAndSelect = (
    price: number,
    occupancy: string,
    modifier: number
  ) => {
    handleSelectRoom(price, occupancy, modifier);
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="link" className="p-0">
          More details
        </Button>
      </DialogTrigger>
      <DialogContent
        showCloseButton={false}
        className="flex h-full w-full flex-col gap-0 rounded-none p-0 md:grid md:h-fit md:max-w-[640px] md:rounded-xl"
      >
        <DialogHeader className="flex flex-row-reverse items-center justify-end gap-x-3 border-b border-gray-200 p-4 md:flex-row md:justify-between md:p-6">
          <DialogTitle
            className={'test-sm font-semibold text-gray-800 md:text-base'}
          >
            Room details
          </DialogTitle>

          <DialogClose>
            <Close className="text-gray-800 md:text-gray-500" />
          </DialogClose>
        </DialogHeader>
        <ScrollArea className="h-full md:max-h-[584px]">
          <div className="flex flex-col gap-y-6 p-4 md:p-6">
            <Carousel>
              {images.map((image) => (
                <div
                  className="relative min-h-[240px] min-w-full flex-1"
                  key={image}
                >
                  <Image
                    src={image}
                    className="rounded-t-lg object-cover"
                    alt="Room photo"
                    fill={true}
                  />
                </div>
              ))}
            </Carousel>

            <h3 className="text-md font-bold text-gray-800 md:text-lg">
              {room.name}, {description}
            </h3>

            <ul className="grid grid-cols-2 space-y-2.5">
              <li className="flex items-center gap-x-2.5">
                <Bed className="text-gray-500" />
                <span className="text-md font-medium">{description}</span>
              </li>
              <li className="flex items-center gap-x-2.5">
                <Users01 size="16" className="text-gray-500" />
                <span className="text-md font-medium">
                  Sleeps {room.capacity.total}
                </span>
              </li>
              <li className="flex items-center gap-x-2.5">
                <CornerUpLeft size="16" className="text-gray-500" />
                <span className="text-md font-medium">
                  {refundable ? 'Refundable' : 'Non-refundable'}
                </span>
              </li>

              {rate.amenities.map((amenity) => {
                const hasWifiIcon = amenity.toLowerCase().includes('wifi');
                const hasBreakfastIcon = amenity
                  .toLowerCase()
                  .includes('breakfast');
                return (
                  <li className="flex items-center gap-x-2.5" key={amenity}>
                    {hasWifiIcon && (
                      <Wifi size="16" className="text-gray-500" />
                    )}
                    {hasBreakfastIcon && (
                      <Coffee
                        viewBox="0 0 16 16"
                        size="16"
                        className="text-gray-500"
                      />
                    )}
                    <span className="text-md font-medium">{amenity}</span>
                  </li>
                );
              })}
            </ul>

            <p dangerouslySetInnerHTML={{ __html: room.description }} />

            {room.amenities.length > 0 && (
              <div className="flex flex-col gap-y-2">
                <Typography size="tmd" className="font-bold">
                  All ameneties
                </Typography>

                <div className="flex flex-wrap items-start gap-2">
                  {room.amenities?.map((feature) => (
                    <div
                      className="text-md flex h-10 items-center justify-center rounded-lg border border-gray-300 bg-white px-3 font-medium text-gray-800"
                      key={feature}
                    >
                      {feature}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </ScrollArea>

        <div className="flex flex-row items-center justify-between border-t border-gray-200 p-6">
          <div>
            <h4 className="text-3xl font-bold">+ {price}</h4>
            <span className="mt-1.5 text-sm font-medium text-gray-500">
              Per traveler <br /> Includes taxes & fees
            </span>
          </div>
          <div className="flex flex-col items-end space-y-3">
            <span className="text-sm font-medium text-gray-500">
              {rate.availableRooms} available
            </span>
            <OccupancyRates
              rateId={rate.id}
              // @ts-ignore temp
              minimumPricePerOccupancy={minimumPricePerOccupancy}
              occupancyRates={occupancyPricing}
              onSelect={onCloseAndSelect}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export const Carousel = ({ children }: PropsWithChildren) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({});

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <section className="embla w-full">
      <div className="relative">
        <div className="embla__viewport overflow-hidden" ref={emblaRef}>
          <div className="embla__container flex flex-row gap-6">{children}</div>
        </div>

        <div className="absolute left-4 top-1/2 z-10 flex h-full min-h-fit -translate-y-1/2 transform items-center">
          <button
            className="flex h-12 w-12 items-center justify-center rounded-full bg-white text-gray-800 shadow-md disabled:hidden"
            onClick={onPrevButtonClick}
            disabled={prevBtnDisabled}
          >
            <ChevronLeft />
          </button>
        </div>

        <div className="absolute right-4 top-1/2 z-10 flex h-full min-h-fit -translate-y-1/2 transform items-center">
          <button
            className="flex h-12 w-12 items-center justify-center rounded-full bg-white text-gray-800 shadow-md disabled:opacity-50 lg:mr-[-20px]"
            onClick={onNextButtonClick}
            disabled={nextBtnDisabled}
          >
            <ChevronRight />
          </button>
        </div>
      </div>
    </section>
  );
};
