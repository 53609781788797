'use client';

import routes from '@config/routes';
import { serverFetcher } from '@instance';
import { LeagueEvent } from '@interfaces/APITravel';
import type { PropertyDetails } from '@interfaces/expedia/property';
import { useBuildPackagesStore } from '@store/build-package-store';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { v2Links } from '@utils/navigation/links';
import { BuildPackageSteps, PageLoader } from '@v2/components';
import { redirect, useParams } from 'next/navigation';
import useSWR from 'swr';
import {
  HotelOverview,
  RoomsList,
  RooomSelectionBottomHeader,
} from './components';

import { PACKAGE_STORAGE } from '@config/storageKeys';
import useEventLoader from '@hooks/use-event-loader';
import useStorage from '@hooks/useStorage';
import { IFormValues } from '@interfaces/buildPackage';
import { useState } from 'react';
import { useScrollToTopOnMount } from '@hooks/use-scroll-top-on-mount';

type Props = {
  event: LeagueEvent;
  eventId: string;
};

export function ViewHotelView({ eventId }: Props) {
  const storage = useStorage();
  useScrollToTopOnMount();

  const [currentPackageData, setCurrentPackageData] = useState<IFormValues>(
    JSON.parse(storage.getItem(PACKAGE_STORAGE) ?? '{}')
  );

  const { startDate, endDate, roomConfigurations } = useBuildPackagesStore(
    (state) => ({
      startDate: state.hotelStartDate,
      endDate: state.hotelEndDate,
      roomConfigurations: state.roomConfigurations,
    })
  );

  const roomSelections = useSearchHotelStore((state) => state.roomSelections);
  const params = useParams<{ id: string }>();

  const isBundle =
    currentPackageData?.selected?.hotel &&
    currentPackageData?.selected?.tickets;

  const { loading } = useEventLoader(eventId, true);
  const { data, isValidating } = useSWR<{
    error: [];
    propertyDetails: PropertyDetails;
  }>(
    routes.propertyDetails,
    (url) =>
      serverFetcher(!params?.id ? null : url, {
        body: {
          id: params?.id,
          startDate: startDate,
          endDate: endDate,
          roomConfigurations,
          isBundle: isBundle,
        },
      }),
    { suspense: true, revalidateIfStale: true, revalidateOnFocus: false }
  );

  if (!params?.id || !data?.propertyDetails) return redirect(v2Links.home);

  if (isValidating || loading) return <PageLoader />;

  return (
    <section className="buy-package-height relative px-4 pt-4 md:mx-auto md:max-w-[1400px] md:px-20 md:pt-12">
      <BuildPackageSteps active="Stay" />

      <HotelOverview propertyDetails={data.propertyDetails} />
      <RoomsList propertyDetails={data.propertyDetails} />
      {roomSelections.length > 0 && <div className="pb-60 md:pb-40" />}
      {roomSelections.length > 0 && (
        <RooomSelectionBottomHeader propertyDetails={data.propertyDetails} />
      )}
    </section>
  );
}
