import type { IFormValues } from '@interfaces/buildPackage';
import type { FormikProps } from 'formik';

export const isHotelValid = (formik: FormikProps<IFormValues>) => {
  try {
    const { location, hotelStartDate, hotelEndDate, roomConfigurations } =
      formik.values;

    return !(
      !location ||
      !hotelStartDate ||
      !hotelEndDate ||
      !roomConfigurations.length
    );
  } catch (error) {
    return false;
  }
};
