import { FormikProps } from 'formik';
import { HeaderFormikProps } from '../Header';
import { getLatLng } from '@utils/location';
import { LocationDropdown } from '@v2/components';
import { InputWithLabel } from './InputWithLabel';
import { MarkerPin01 } from '@v2/ui';

function BaseLocationFilter(props: { formik: FormikProps<HeaderFormikProps> }) {
  const {
    formik: { values, setFieldValue },
  } = props;
  const value = values.location?.name ?? '';
  const dropdownValue = values.location?.city_name ?? '';

  const onSelectLocation = async (value) => {
    const fullName = value.Text.split(', ');
    getLatLng(value.PlaceId).then((res) => {
      setFieldValue('location', {
        id: value.PlaceId,
        name: value.Text,
        country_name: fullName[fullName.length - 1],
        latitude: res.Geometry.Point[1],
        longitude: res.Geometry.Point[0],
      });
    });
  };

  return (
    <LocationDropdown value={dropdownValue} onChange={onSelectLocation}>
      <InputWithLabel
        icon={
          <MarkerPin01
            className="text-gray-800"
            pathProps={{ stroke: 'currentColor' }}
          />
        }
        label={'Location'}
        value={value}
      />
    </LocationDropdown>
  );
}

export { BaseLocationFilter as BaseLocationFilter };
