import { useCurrencyContext } from '@contexts/currency-context';
import { Capacity, OccupancyPricing } from '@interfaces/expedia/property';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import {
  Button,
  Divider,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuTrigger,
  Minus,
  Plus,
  ScrollArea,
} from '@v2/ui';
import { cn } from '@v2/utils';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';

type OccupancyRatesProps = {
  rateId: number;
  minimumPricePerOccupancy: Record<string, number>;
  occupancyRates: OccupancyPricing[];
  onSelect: (price: number, ocupancy: string, modifier: number) => void;
};

export function OccupancyRates(props: OccupancyRatesProps) {
  const { occupancyRates, onSelect, minimumPricePerOccupancy, rateId } = props;
  const [modifierActive, setModifierActive] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const roomSelections = useSearchHotelStore((state) => state.roomSelections);

  const { priceRate, currency } = useCurrencyContext();
  const selectableOccupancies = useSearchHotelStore(
    (state) => state.selectableOccupancies
  );

  const getPriceDifference = useCallback(
    (pricing) => {
      const price = (
        +pricing.total.value -
        (minimumPricePerOccupancy?.[pricing.occupancy] ?? 0)
      ).toFixed(2);

      return formatCurrencyV2(price, priceRate, currency);
    },
    [minimumPricePerOccupancy, priceRate, currency]
  );

  const buildOccupancyDescription = (capacity: Capacity) => {
    let description = `${capacity.adults} adult${capacity.adults !== 1 ? 's' : ''}`;
    if (capacity.children)
      description += `, ${capacity.children} ${capacity.children !== 1 ? 'children' : 'child'}`;
    if (capacity.infants)
      description += `, ${capacity.infants} infant${capacity.infants !== 1 ? 's' : ''}`;

    return description;
  };

  const clearRateSelection = () => {
    const occupancies = [...selectableOccupancies];

    const roomSelectionToBeDeleted = roomSelections.filter(
      (room) => room.rateId === rateId
    );

    for (const room of roomSelectionToBeDeleted) {
      occupancies.push(room.occupancy);
    }

    const newRoomSelections = roomSelections.filter(
      (selection) => selection.rateId !== rateId
    );

    useSearchHotelStore.setState({
      roomSelections: newRoomSelections,
      selectableOccupancies: [...occupancies],
    });
  };

  const isSelected =
    roomSelections.find((item) => item.rateId === rateId) || false;

  const roomQuantites = useMemo(() => {
    let total = 0;
    roomSelections
      .filter((s) => s.rateId === rateId)
      .forEach((selection) => {
        total += 1;
      });

    return total;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomSelections]);

  return (
    <DropdownMenu
      modal={false}
      onOpenChange={(val) => {
        if (isOpen) {
          setIsOpen(false);
          setModifierActive(null);
        }
      }}
      open={isOpen}
    >
      <DropdownMenuTrigger asChild>
        {/* <div> */}
        <div>
          {!isSelected && (
            <Button
              size="xl"
              onClick={() => {


                if (occupancyRates.length === 1) {

                  onSelect(
                    Number(occupancyRates[0].total.value),
                    occupancyRates[0].occupancy,
                    1
                  );
                  return
                }
                setIsOpen(true);
                setModifierActive(null);
              }}
            >
              Select
            </Button>
          )}
          {/* </div> */}

          {isSelected && (
            <div className="border-gary-300 flex items-center gap-x-3 rounded-lg border p-3">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  clearRateSelection();
                  setIsOpen(false);
                }}
              >
                <Minus size="20" className="text-gray-800" />
              </button>
              <span>
                {roomQuantites} {pluralize('room', roomQuantites)}{' '}
              </span>
              <button
                className={cn(
                  !modifierActive ? 'text-gray-800' : 'text-primary-500'
                )}
                onClick={() => {
                  setIsOpen(true);
                  setModifierActive(1);
                }}
              >
                <Plus size="20" className="text-gray-800" />
              </button>
            </div>
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-[250px] bg-white" align="end">
        <DropdownMenuSub>
          <ScrollArea className="max-h-[200px]">
            {occupancyRates.map((pricing, i) => {
              return (
                <>
                  <DropdownMenuItem
                    className="flex items-center justify-between"
                    onSelect={() => {
                      setIsOpen(false);
                      onSelect(
                        Number(pricing.total.value),
                        pricing.occupancy,
                        1
                      );
                      setModifierActive(null);
                    }}
                    key={pricing.occupancy}
                  >
                    <h3 className="text-xl font-bold text-gray-800">
                      + {getPriceDifference(pricing)}{' '}
                    </h3>
                    <span className="text-sm font-medium text-gray-500">
                      {buildOccupancyDescription(pricing.capacity)}
                    </span>
                  </DropdownMenuItem>
                  {i !== occupancyRates.length - 1 && <Divider />}
                </>
              );
            })}
          </ScrollArea>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
